import axios from "axios";

export interface PostAnswerResponse {
  correctId: string;
}

export async function postQOTWAnswer({
  optionId,
  userId,
}: {
  optionId: string;
  userId: string;
}) {
  const response = await axios.post<PostAnswerResponse>(
    `${process.env.REACT_APP_QOTW_API_URL}/questions/v1/answer`,
    { optionId, userId }
  );

  return response.data;
}
