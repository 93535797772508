import { fetchArticles } from "@api-calls/fetchArticles";
import GTMACTIONS from "@config/gtmActions";
import { datalayerPush } from "@lib/gtm";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ArticleCard, ArticleCardSkeleton } from "ui-2";
import { usePromise } from "utils";
import { Article } from "../../../types";

const RecommendedArticles = () => {
  const { t } = useTranslation();

  const [{ data, isSuccess }, { exec: fetchArticleList }] =
    usePromise(fetchArticles);

  const gaClickArticle = (article: Article) => {
    const GAclickEvent = {
      ...GTMACTIONS.ARTICLES,
      article_title: article?.title,
      click_text: article?.title,
      click_url: article?.url,
    };
    datalayerPush(GAclickEvent);
  };

  useEffect(() => {
    fetchArticleList();
  }, []);

  return (
    <div className="label2 lg:headline5 flex flex-col items-start gap-4 px-5 lg:px-0">
      {t(`addEditPet.recommendedArticles`)}
      <div
        className="grid_hphv2 w-full gap-6 md:grid-cols-2 pb-10"
        role="list"
        aria-label="Recommended Articles"
      >
        {isSuccess
          ? data?.map((article: Article) => (
              <div
                onClick={() => gaClickArticle(article)}
                key={article.id}
                className="lg:96"
              >
                <ArticleCard article={article} />
              </div>
            ))
          : Array.from({ length: 4 }).map((_, index) => (
              <ArticleCardSkeleton key={`key-${index}`} />
            ))}
      </div>
    </div>
  );
};

export default RecommendedArticles;
