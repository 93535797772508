import * as Modal from "../../../../components/Modal";
import { Button, Loader } from "ui-2";
import { RainbowIcon } from "@icons/RainbowIcon";
import { MY_PETS } from "@constants/myPets";
import { useTranslation, Trans } from "react-i18next";
import { OK } from "@constants/index";
export interface PetArchivedModalProps {
  petName: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (goToRememberedPets: boolean) => void;
  isLoading: boolean;
}

export function PetArchivedModal({
  petName,
  isOpen,
  onClose,
  onConfirm,
  isLoading,
}: PetArchivedModalProps) {
  const { t } = useTranslation();

  return (
    <Modal.Root open={isOpen} onOpenChange={onClose}>
      <Modal.SideModal title={t(MY_PETS.DECEASED_PET.ARCHIVE_PROFILE_TITLE)}>
        <div className="flex h-full flex-col justify-between">
          <div className="flex flex-col gap-4 p-5 sm:items-center">
            <div className="w-[310px] text-base">
              {t(MY_PETS.DECEASED_PET.ARCHIVE_PROFILE_TEXT, { petName })}
            </div>
            <div className="w-[310px] text-base leading-6">
              <Trans
                i18nKey={MY_PETS.DECEASED_PET.ARCHIVE_PROFILE_SUB_TEXT}
                components={{
                  icon: <RainbowIcon className="mb-1 inline" />,
                  b: <b />,
                }}
              />
            </div>
            {isLoading && <Loader />}
          </div>
          <div className="flex flex-col-reverse justify-end gap-4 p-5 sm:flex-row sm:border-t">
            <Button
              variant="blueText"
              onClick={() => onConfirm(true)}
              disabled={isLoading}
            >
              {t(MY_PETS.DECEASED_PET.GO_TO_REMEMBERED_PETS)}
            </Button>
            <Button
              variant="primary"
              classes="sm:min-w-[207px]"
              onClick={() => onConfirm(false)}
              disabled={isLoading}
            >
              {t(OK)}
            </Button>
          </div>
        </div>
      </Modal.SideModal>
    </Modal.Root>
  );
}
