import axios from "axios";

export interface FetchQOTWResponse {
  id: string;
  text: string;
  isAnswered: boolean;
  options: {
    id: string;
    text: string;
  }[];
}

export async function fetchQOTW(userId: string) {
  const response = await axios.get<FetchQOTWResponse>(
    `${process.env.REACT_APP_QOTW_API_URL}/questions/v1/question`, {
      params: { userId }
    }
  );

  return response.data;
}
