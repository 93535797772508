import PetDetails from "../PetDetails";
import MyPetsSwiper from "../../../../components/MyPetsSwiper";
import { PetType } from "../../../../../types";
import { useSearchParams } from "react-router-dom";

const PetsWrapper = ({ petsData }: { petsData: Array<PetType> }) => {

  const petId = useSearchParams()[0].get("petId") ?? "";
  const activePet = petsData.find((pet) => pet.petId === petId);

  return (
    <div>
      <MyPetsSwiper petsData={petsData} />
      {activePet && <PetDetails name={activePet.petName} />}
    </div>
  );
};

export default PetsWrapper;
