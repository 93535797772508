import { Alert } from "@icons/Alert";
import * as Modal from "../../../../components/Modal";
import { Button, Loader } from "ui-2";
import { Trans, useTranslation } from "react-i18next";
import { MY_PETS } from "@constants/myPets";
import { CANCEL } from "@constants/index";

export interface DeleteProfileModalProps {
  petName: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isLoading: boolean;
}

export function DeleteProfileModal({
  petName,
  isOpen,
  onClose,
  onConfirm,
  isLoading,
}: DeleteProfileModalProps) {
  const { t } = useTranslation();

  const deleteProfile = () => {
    onConfirm();
  };

  const cancel = () => {
    onClose();
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={onClose}>
      <Modal.SideModal title={t(MY_PETS.DECEASED_PET.DELETE_PROFILE_TITLE)}>
        <div className="flex h-full flex-col justify-between">
          <div className="flex flex-col items-center gap-4 p-5">
            <div className="flex justify-center sm:w-[300px]">
              <Alert />
            </div>
            <div className="w-[310px] text-base">
              <Trans
                i18nKey={MY_PETS.DECEASED_PET.DELETE_PRIMARY_TEXT}
                values={{ petName }}
                components={{ b: <b /> }}
              />
            </div>
            <div className="w-[310px] text-base">
              {t(MY_PETS.DECEASED_PET.DELETE_SUB_TEXT)}
            </div>
            {isLoading && <Loader />}
          </div>
          <div className="flex flex-col-reverse justify-end gap-4 p-5 sm:flex-row sm:border-t">
            <Button variant="blueText" onClick={cancel} disabled={isLoading}>
              {t(CANCEL)}
            </Button>
            <Button
              variant="dangerOutlined"
              classes="sm:min-w-[207px]"
              onClick={deleteProfile}
              disabled={isLoading}
            >
              {t(MY_PETS.DECEASED_PET.DELETE_PET_PROFILE)}
            </Button>
          </div>
        </div>
      </Modal.SideModal>
    </Modal.Root>
  );
}
