import { FetchQOTWResponse } from "@api-calls/fetchQOTW";
import { postQOTWAnswer } from "@api-calls/postQOTWAnswer";
import { useToastConfig } from "@components/toast";
import { QOTW } from "@constants/qotw";
import { Auth, useAuth } from "@hooks/useAuth";
import { Check } from "@icons/Check";
import { Cross } from "@icons/Cross";
import { Link } from "@icons/Link";
import classnames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "ui-2";
import { usePromise } from "utils";
import * as Modal from "../Modal";
import { datalayerPush } from "@lib/gtm";
import GTM_ACTIONS from "@config/gtmActions";

export function QOTWForm({
  questionData,
  onSubmitSuccess,
}: Readonly<{
  questionData: FetchQOTWResponse;
  onSubmitSuccess?: () => void;
}>) {
  const { t } = useTranslation();
  const { showToast } = useToastConfig();
  const [selectedChoice, setSelectedChoice] = useState<string | null>(null);
  const [state, { exec: saveAnswer }] = usePromise(postQOTWAnswer);
  const auth: Auth = useAuth();
  const userId = auth?.user?.UID;

  function handleSubmit() {
    if (selectedChoice === null) {
      return;
    }
    userId &&
      saveAnswer({
        optionId: selectedChoice,
        userId,
      });
    const selectedText = questionData.options.find(
      (o) => o.id === selectedChoice
    )?.text;
    datalayerPush({
      ...GTM_ACTIONS.QOTW_SUBMIT,
      click_text: `${selectedText}_submit`,
    });
  }

  function handleSelect({ id, text }: { id: string; text: string }) {
    setSelectedChoice(id);
    datalayerPush(GTM_ACTIONS.QOTW_SELECT_OPTION, {
      click_text: text,
    });
  }

  function handleDone() {
    datalayerPush(GTM_ACTIONS.QOTW_DONE);
    onSubmitSuccess?.();
  }

  function handleKnowMore() {
    datalayerPush(GTM_ACTIONS.QOTW_CLICK_KNOW_MORE);
    window.open(
      t(`QOTW.relatedArticleURLs.${questionData.text}`) ?? "",
      "_blank"
    );
  }

  React.useEffect(() => {
    if (state.isError) {
      showToast({
        message: t(QOTW.ERROR),
        type: "error",
      });
    }
  }, [state.isError, showToast]);

  const isAnswerCorrect = state.data?.correctId === selectedChoice;

  return (
    <form>
      <div className="flex flex-col items-center gap-10 p-6 text-left">
        {!state.isSuccess && (
          <img
            src={process.env.REACT_APP_URL + "/images/qotw.svg"}
            className="h-16 w-16"
            aria-hidden
            alt=""
          />
        )}
        {state.isSuccess && (
          <div className="mt-4 flex flex-col items-center gap-2">
            {isAnswerCorrect ? (
              <Check />
            ) : (
              <Cross className="fill-brand-color-library-gray-500" />
            )}
            <span className="headline4 md:headline3 text-brand-color-library-gray-800">
              {isAnswerCorrect ? t(QOTW.CORRECT) : t(QOTW.INCORRECT)}
            </span>
            {!isAnswerCorrect && (
              <span className="body3 md:body2 text-brand-color-library-gray-800">
                {t(QOTW.PLAY_AGAIN)}
              </span>
            )}
          </div>
        )}
        <div className="mx-auto w-full md:w-[58%]">
          <div className="mb-4 w-[90%] text-left md:w-[76%]">
            <h2 className="mb-4 text-lg">
              {t(`QOTW.questions.${questionData.text}`)}
            </h2>
          </div>
          {questionData.options.map(({ text, id }) => {
            const isSelected = selectedChoice === id;
            const isCorrect = state.data?.correctId === id;
            const isIncorrect = state.isSuccess && !isAnswerCorrect;

            return (
              <label
                key={id}
                className={classnames(
                  "mb-2 flex cursor-pointer items-center rounded-md border p-4 text-lg",
                  isCorrect &&
                    "border-brand-color-library-green-800 bg-brand-color-library-green-100",
                  isSelected &&
                    isIncorrect &&
                    "border-brand-color-library-red-800 bg-brand-color-library-red-100",
                  !state.isSuccess &&
                    (isSelected
                      ? "border-brand-color-library-blue-500 bg-brand-color-library-blue-100"
                      : "border-gray-300 hover:bg-gray-100")
                )}
              >
                <input
                  type="radio"
                  name="qotw"
                  value={id}
                  checked={isSelected}
                  onChange={() => handleSelect({ id, text })}
                  className="hidden"
                  disabled={state.isSuccess || state.isPending}
                />
                <span
                  className={classnames(
                    "mr-2 flex h-5 w-5 items-center justify-center rounded-full border",
                    isSelected
                      ? "border-brand-color-library-blue-500 bg-brand-color-library-blue-500"
                      : "border-gray-300"
                  )}
                >
                  {isSelected && (
                    <span className="h-[4px] w-[4px] rounded-full bg-white" />
                  )}
                </span>
                {t(`QOTW.options.${text}`)}
              </label>
            );
          })}
        </div>
        {state.isSuccess && (
          <Button
            type="button"
            classes="hidden md:block"
            variant="blueText"
            onClick={handleKnowMore}
          >
            {t(QOTW.LEARN_MORE)} <Link className="fill-brand-primary" />
          </Button>
        )}
      </div>
      <Modal.Footer>
        {state.isSuccess ? (
          <>
            <Modal.Close asChild>
              <Button
                onClick={handleDone}
                type="button"
                classes="md:label1 w-full md:min-w-[208px] md:w-fit"
                data-testid="submit-button"
                disabled={selectedChoice === null || state.isPending}
              >
                {t(QOTW.DONE)}
              </Button>
            </Modal.Close>
            <Button
              type="button"
              classes="block md:hidden"
              variant="blueText"
              onClick={handleKnowMore}
            >
              {t(QOTW.LEARN_MORE)} <Link className="fill-brand-primary" />
            </Button>
          </>
        ) : (
          <Button
            type="submit"
            classes="md:label1 w-full md:min-w-[208px] md:w-fit"
            data-testid="submit-button"
            disabled={selectedChoice === null || state.isPending}
            onClick={handleSubmit}
          >
            {t(QOTW.SUBMIT)}
          </Button>
        )}
      </Modal.Footer>
    </form>
  );
}
