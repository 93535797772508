import React from "react";

export function FooterItem({
  icon,
  content,
}: Readonly<{ icon: JSX.Element; content: React.ReactNode }>) {
  return (
    <div className="body3 lg:body2 flex w-[64px] flex-grow flex-col lg:min-w-[140px] lg:flex-row lg:items-center lg:gap-2">
      <div className="flex h-6 w-6 items-center justify-center fill-[#66676B] lg:h-8 lg:w-8">
        <div className="h-full w-full">{icon}</div>
      </div>
      <div>{content}</div>
    </div>
  );
}
