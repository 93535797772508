import { AddIcon, Cat, Dog, RememberedPetIconLarge } from "../../icons/";
import { Button } from "ui-2";
import { MY_PETS } from "../../constants/myPets";
import { useTranslation } from "react-i18next";
import { ROUTES_PATH } from "../../constants/routes";
import { useMyPets } from "../../hooks/useMyPet";
import useSearchPersistantNavigate from "../../hooks/useSearchPersistantNavigate";
import { classnames } from "utils";
import { datalayerPush } from '../../lib/gtm'; 
import GTM_ACTIONS from "../../config/gtmActions"; 


const AddPetButton = (props: {
  isMobile: boolean;
  showAddPetButtonInHeader: boolean;
}) => {
  const { t } = useTranslation();
  const addPet = t(MY_PETS.CAROUSAL_ADD_PET);
  const { isViewRememberedPets } = useMyPets();
  const searchPersistantNavigate = useSearchPersistantNavigate();

  if (isViewRememberedPets) return null;
  return (
    <Button
      variant="blueOutlined"
      classes={`!rounded-full m-2 ${
        props.showAddPetButtonInHeader && `mt-0 mb-0`
      } h-[48px] flex items-center justify-center rounded-full border`}
      prefixIcon={<AddIcon />}
      data-testid={"AddPetButton"}
      onClick={() => {
        datalayerPush(GTM_ACTIONS.MY_PETS_CAROUSEL_ADD_A_PET); 
        searchPersistantNavigate(`${ROUTES_PATH.ADD_PET}`);
      }}
    >
      <div className="label2 truncate">{addPet}</div>
    </Button>
  );
};

const defaultImage = (
  petName: string,
  isSelected: boolean,
  isMobile: boolean
) => {
  const padding = isMobile ? "p-3" : "p-4";
  const paddingSelected = isMobile ? "p-4" : "p-5";
  return (
    <button
      tabIndex={0}
      className={`rounded-full border-2 bg-[#E6E6E6] text-center ${
        !isSelected ? `focus:scale-110 focus:border-blue-500` : ""
      } ${isSelected ? "border-blue-500 " + paddingSelected : padding}`}
    >
      <div
        className={`label2 h-[19px] w-[19px] align-middle`}
        data-testid="pet-initial-name"
      >
        {petName.slice(0, 2)}
      </div>
    </button>
  );
};

const MyPetsHeader = (configs: {
  showAddPetButtonInHeader: boolean;
  isMobile: boolean;
  sixPetsCriteria: boolean;
}) => {
  const { t } = useTranslation();
  const { isViewRememberedPets } = useMyPets();
  return (
    <div
      className={`flex flex-row p-[18px] ${
        !configs.sixPetsCriteria && `!pb-0`
      }  items-center`}
    >
      <div className="my-2 grow justify-start">
        <span className="flex flex-row">
          <div className="mr-2 flex items-center justify-center">
            {!isViewRememberedPets ? (
              <>
                <span>{Dog}</span>
                <span>{Cat}</span>
              </>
            ) : (
              <span>{RememberedPetIconLarge}</span>
            )}
          </div>
          <span
            className={classnames(
              "headline3",
              isViewRememberedPets && " text-brand-color-library-gray-500"
            )}
          >
            {isViewRememberedPets
              ? t(MY_PETS.REMEMBRED_PETS.HEADING)
              : t(MY_PETS.CAROUSAL_HEADER)}
          </span>
        </span>
      </div>
      {configs.showAddPetButtonInHeader ? (
        <AddPetButton
          isMobile={configs.isMobile}
          showAddPetButtonInHeader={configs.showAddPetButtonInHeader}
        />
      ) : null}
    </div>
  );
};

export { defaultImage, MyPetsHeader, AddPetButton };
