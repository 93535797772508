import axios from "axios";
import { API_URLS } from "../constants/apiUrls";

interface Article {
  id: string;
  category: string;
  image: string;
  shortDescription: string;
  species: string;
  title: string;
  url: string;
  _index: string;
}

interface FetchArticlesResponse {
  data: Article[];
  serviceStatus: {
    message: string;
  };
}

export async function fetchArticles() {
  return axios
    .get<FetchArticlesResponse>(
      `${process.env.REACT_APP_ARTICLE_API_URL}${API_URLS.ARTICLES}`
    )
    .then((res) => res.data.data || []);
}
