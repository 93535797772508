import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Tab, Tabs } from "ui-2";
import { usePromise } from "utils";
import { fetchPetById } from "../../../../api-calls/fetchPetById";
import GTM_ACTIONS from "../../../../config/gtmActions";
import { MY_PETS } from "../../../../constants/index";
import { datalayerPush } from "../../../../lib/gtm";
import BasicInfoCardWrapper from "../BasicInfoCardWrapper/BasicInfoCardWrapper";
import Diet from "../Diet";
import Milestones from "../Milestones";
import PetHeadingOptions from "../PetsNameHeadingWrapper";
import DeceasedPetComponent from "../DeceasedPetComponent";
import { useSnowFlakePets } from "@hooks/useSnowflakeData";
import { isTheSamePet } from "@lib/common";
import { PetStatusEnum } from "../../../../types/pets";
import { BasicInfoCardSkeleton } from "@components/BasicInfoCard/BasicInfoCardSkeleton";

const tabNames = ["Profile", "Milestones", "Diet"];
const tabNameToIndex = (tabName: string) => tabNames.indexOf(tabName);
const indexToTabName = (index: number) => tabNames[index];

const PetDetails = ({ name }: { name: string }) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const petId = searchParams.get("petId");
  const activeTab = searchParams.get("tab") ?? "Profile";
  const [petRequestState, { exec: fetchPet }] = usePromise(fetchPetById);
  const { snowflakePets } = useSnowFlakePets();
  const { rememberedPets } = snowflakePets;

  const fetchActivePet = useCallback(() => {
    petId && fetchPet(petId);
  }, [petId, fetchPet]);

  useEffect(() => {
    fetchActivePet();
  }, [fetchActivePet]);

  const handleTabChange = useCallback(
    function handleTabChange(index: number) {
      const tabName = indexToTabName(index);
      trackTabClick(tabName);
      setSearchParams({ petId: petId ?? "", tab: tabName });
    },
    [setSearchParams]
  );

  const pet = petRequestState.data;
  const isRememberedPet =
    pet &&
    rememberedPets?.some(
      (rememberedPet) =>
        isTheSamePet(rememberedPet, pet) &&
        pet.petStatusId === PetStatusEnum.ACTIVE
    );

  return (
    <div className="flex flex-col">
      <div className="flex flex-col">
        <div className="headline4 lg:headline1 ml-5 flex items-center lg:ml-0">
          <span>{name}</span>
          {petRequestState.isSuccess && (
            <PetHeadingOptions petData={petRequestState.data} />
          )}
        </div>
      </div>

      {isRememberedPet && (
        <DeceasedPetComponent petId={pet.petId} petName={pet.petName} />
      )}
      {!isRememberedPet && (
        <div data-testid="tabs-component">
          <Tabs
            classes="w-full px-5 lg:px-0 lg:pt-0"
            activeIndex={tabNameToIndex(activeTab)}
            onActiveIndexChange={handleTabChange}
          >
            <Tab title={t(MY_PETS.PROFILE.HEADING)}>
              <div className="mt-6 flex flex-col items-center justify-center gap-8">
                {petRequestState.isSuccess && (
                  <BasicInfoCardWrapper petData={petRequestState.data} />
                )}
                {petRequestState.isPending && <BasicInfoCardSkeleton />}
              </div>
            </Tab>
            <Tab
              title={t(MY_PETS.MILESTONES.HEADING)}
              disabled={petRequestState.isPending}
            >
              <div className="mt-6 flex items-center justify-center gap-8">
                {petRequestState.isSuccess && (
                  <Milestones
                    petId={petRequestState.data.petId}
                    petType={petRequestState.data.petType}
                    lifestageId={Number(petRequestState.data.petLifeStageId)}
                    petName={petRequestState.data.petName}
                  />
                )}
              </div>
            </Tab>
            <Tab
              title={t(MY_PETS.DIET.HEADING)}
              disabled={petRequestState.isPending}
            >
              {petRequestState.isSuccess && (
                <div className="mt-6 flex items-center justify-center gap-8">
                  <Diet
                    pet={petRequestState.data}
                    refetchPet={fetchActivePet}
                  />
                </div>
              )}
            </Tab>
          </Tabs>
        </div>
      )}
    </div>
  );
};

function trackTabClick(tabName: string) {
  datalayerPush(GTM_ACTIONS.VIEW_TAB, {
    click_text: tabName,
    action:
      tabName === "Milestones"
        ? "milestone_click"
        : GTM_ACTIONS.VIEW_TAB.action,
  });
}

export default PetDetails;
