import FoodTypeModal from "../../components/FoodTypeModal/FoodTypeModal";
import { useTranslation } from "react-i18next";
import { DietInfoCardProps } from "../../../types";
import { MY_PETS } from "../../constants";
import { editIcon2 } from "../../icons";
import { FoodQuizLink } from "./FoodQuizLink";
import * as Modal from "../../components/Modal";
import GTM_ACTIONS from "../../config/gtmActions";
import { datalayerPush } from "../../lib/gtm";
import { useFeatureFlagsConfig } from "../../hooks/useFeatureFlags";

const DietInfoCard = ({
  DietData,
  onFoodSelectionSaved,
  onClose,
  children,
}: {
  DietData: DietInfoCardProps;
  onFoodSelectionSaved: () => void;
  onClose: () => void;
  children?: React.ReactNode;
}) => {
  const { t } = useTranslation();
  const {
    petFoodTypeId,
    hillsProductImage,
    hillsProductDesc,
    petName,
    petFoodTypeInfo,
    petId,
    petType,
  } = DietData;

  const handleSelectFoodClick = () => {
    datalayerPush(GTM_ACTIONS.MY_PETS_DIET_SELECT_FOOD_CLICK);
  };

  const { foodSelect, foodSelectQuiz } = useFeatureFlagsConfig();

  return (
    <div aria-label="diet-info-card" className="flex w-full flex-col">
      <Modal.Root>
        {petFoodTypeId === 1 && hillsProductImage && hillsProductDesc ? (
          <div className="flex flex-col justify-around pb-2">
            <div className="flex flex-row items-center justify-between self-stretch">
              <div className="label2 lg:headline5">
                {t(MY_PETS.PROFILE.LABEL2)}
              </div>
            </div>
            <div className="flex w-full flex-col gap-2 self-stretch rounded-lg p-4 shadow-dp2 lg:gap-4 lg:p-6">
              <div className="flex flex-row gap-2">
                <div className="flex h-[90px] w-[75px] flex-col items-center justify-center lg:h-[98px] lg:w-[82px]">
                  <img
                    aria-label="hills-product"
                    src={hillsProductImage}
                    alt="petData"
                    className="w-[75px] lg:w-[82px]"
                  />
                </div>
                <div className="flex flex-grow flex-col justify-center gap-1 lg:gap-2">
                  <div aria-label="hills-product-name" className="body2">
                    {hillsProductDesc}
                  </div>
                </div>
                <div className="h-[24px] w-[24px] flex-shrink-0 cursor-pointer fill-[#0054A4]">
                  <Modal.Trigger className="h-[24px] w-[24px] cursor-pointer fill-[#0054A4]">
                    {editIcon2}
                  </Modal.Trigger>
                </div>
              </div>
              <div className="mt-4">{children}</div>
            </div>
            {foodSelectQuiz && (
              <>
                <div
                  data-testid="food-quiz-question"
                  className="label2 lg:headline5 mt-8 w-full text-left"
                >
                  {t(MY_PETS.DIET.FOOD_QUIZ_QUESTION, { petName })}
                </div>
                <div className="mt-4 w-full sm:w-auto">
                  <FoodQuizLink petType={DietData.petType} />
                </div>
              </>
            )}
          </div>
        ) : petFoodTypeId === 2 || petFoodTypeId === 3 ? (
          <div className="flex flex-col justify-around pb-2">
            {foodSelect && (
              <>
                <div className="flex flex-row items-center justify-between">
                  <div className="label2 lg:headline5">
                    {t(MY_PETS.PROFILE.LABEL2)}
                  </div>
                  <Modal.Trigger className="flex cursor-pointer items-center fill-[#0054A4]">
                    <div className="flex items-center">
                      <div className="h-[24px] w-[24px]">{editIcon2}</div>
                      <span className="ml-2 font-bold text-brand-primary">
                        {t(MY_PETS.DIET.EDIT)}
                      </span>
                    </div>
                  </Modal.Trigger>
                </div>
                <div className="mt-4 flex w-full flex-col gap-2 self-stretch lg:gap-4">
                  <div className="flex flex-row gap-2">
                    <div className="flex flex-grow flex-col justify-center gap-1 lg:gap-2">
                      <div aria-label="custom-food" className="body2">
                        {petFoodTypeInfo}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {foodSelectQuiz && (
              <>
                <div
                  data-testid="food-quiz-question"
                  className="label2 lg:headline5 mt-8 w-full text-left"
                >
                  {t(MY_PETS.DIET.FOOD_QUIZ_QUESTION, { petName })}
                </div>
                <div className="mt-4 w-full sm:w-auto">
                  <FoodQuizLink petType={DietData.petType} />
                </div>
              </>
            )}
          </div>
        ) : (
          <div aria-label="diet-info-card" className="flex w-full flex-col">
            {foodSelect && (
              <div className="flex flex-row items-center justify-between self-stretch">
                <div className="label2 lg:headline5">
                  {t(MY_PETS.PROFILE.LABEL2)}
                </div>
              </div>
            )}
            <div className="flex flex-col items-center sm:items-start">
              {foodSelect && (
                <Modal.Trigger
                  data-testid="select-food"
                  className="mt-4 h-[42px] w-full justify-center rounded border border-brand-primary px-4 text-lg font-semibold text-brand-primary sm:w-auto"
                  onClick={handleSelectFoodClick}
                >
                  {t(MY_PETS.DIET.SELECT_FOOD)}
                </Modal.Trigger>
              )}
              {foodSelectQuiz && (
                <>
                  <div
                    data-testid="food-quiz-question"
                    className="label2 lg:headline5 mt-8 w-full text-left"
                  >
                    {t(MY_PETS.DIET.FOOD_QUIZ_QUESTION, { petName })}
                  </div>
                  <div className="mt-4 w-full sm:w-auto">
                    <FoodQuizLink petType={DietData.petType} />
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        <FoodTypeModal
          petName={petName}
          petId={petId}
          petType={petType}
          onFoodSelectionSaved={onFoodSelectionSaved}
          onClose={onClose}
        />
      </Modal.Root>
    </div>
  );
};

export default DietInfoCard;
