import * as Modal from "../../../../components/Modal";
import { Button, Loader } from "ui-2";
import { PetChange } from "../../../../types/pets";
import { PetAddUpdateItem } from "../PetsUpdatesNotification/PetAddUpdateItem";
import { MY_PETS } from "@constants/myPets";
import { t } from "i18next";
export interface RemovePetsModalProps {
  changesToRemove: PetChange[];
  isOpen: boolean;
  onClose: () => void;
  onGoBack: () => void;
  onConfirm: () => void;
  isLoading: boolean;
}

export function RemovePetsConfirmModal({
  changesToRemove,
  isOpen,
  onClose,
  onGoBack,
  onConfirm,
  isLoading,
}: RemovePetsModalProps) {
  const removeAndContinue = () => {
    onConfirm();
  };

  const cancel = () => {
    onGoBack();
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={onClose}>
      <Modal.SideModal title={t(MY_PETS.REMOVE_PETS_MODAL.TITLE)}>
        <div className="flex h-full flex-col justify-between">
          <div className="flex flex-col items-center gap-4 sm:gap-8 p-5">
            <div className="w-full max-w-[553px] text-base sm:text-lg">
              {t(MY_PETS.REMOVE_PETS_MODAL.DESCRIPTION)}
            </div>
            <div className="flex w-full max-w-[553px] flex-col gap-4">
              {changesToRemove.map((petChange) => {
                return (
                  <PetAddUpdateItem
                    key={petChange.petId}
                    petChange={petChange}
                    showChanges={false}
                    showNewBadge={false}
                  />
                );
              })}
            </div>
            <div className="w-full max-w-[553px] text-base sm:text-lg">
              {t(MY_PETS.REMOVE_PETS_MODAL.WARNING)}
            </div>
            {isLoading && <Loader />}
          </div>
          <div className="flex flex-col-reverse justify-end gap-4 p-5 sm:flex-row sm:border-t">
            <Button variant="blueText" onClick={cancel} disabled={isLoading}>
              {t(MY_PETS.REMOVE_PETS_MODAL.GO_BACK)}
            </Button>
            <Button
              variant="primary"
              classes="sm:min-w-[207px]"
              onClick={removeAndContinue}
              disabled={isLoading}
            >
              {t(MY_PETS.REMOVE_PETS_MODAL.REMOVE_AND_CONTINUE)}
            </Button>
          </div>
        </div>
      </Modal.SideModal>
    </Modal.Root>
  );
}
