import { useTranslation } from "react-i18next";
import { ChevronRight } from "../../icons/ChevronRight";
import { SettingsIcon } from "../../icons/Settings";
import { ACCOUNT_SETTINGS } from "../../constants";
import { Link, useNavigate } from "react-router-dom";

const AccountSettings = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="m-auto overflow-hidden rounded-lg bg-white shadow-dp2">
      <div className="flex flex-col">
        <div
          className="cursor-pointer bg-white sm:bg-gray-100"
          onClick={() => navigate("/account-settings")}
          tabIndex={0}
        >
          <div className="m-6 flex items-center justify-between">
            <div>
              <div className="label1 mb-2 flex gap-2">
                {SettingsIcon}
                <span data-testid="header" aria-label={`${t(ACCOUNT_SETTINGS.HEADING)}`}>{`${t(
                  ACCOUNT_SETTINGS.HEADING
                )}`}</span>
              </div>
              <div className="body2" data-testid="description">
                {`${t(ACCOUNT_SETTINGS.DESCRIPTION)}`}
              </div>
            </div>
            <div data-testid="rightArrow">
              <ChevronRight className="fill-brand-color-library-gray-800" />
            </div>
          </div>
        </div>
        <div className="hidden bg-white sm:block">
          <div className="m-6 flex flex-col gap-4">
            <Link
              className="link2 text-blue-500 underline"
              to={"/account-settings/communication-preferences"}
              aria-label={`${t(ACCOUNT_SETTINGS.COMMUNICATION_PREFERENCES)}`}
            >
              {t(ACCOUNT_SETTINGS.COMMUNICATION_PREFERENCES)}
            </Link>
            <Link
              className="link2 text-blue-500 underline"
              to={"/account-settings/change-password"}
              aria-label={`${t(ACCOUNT_SETTINGS.PASSWORD_CHANGE)}`}
            >
              {t(ACCOUNT_SETTINGS.PASSWORD_CHANGE)}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;
