import * as Modal from "../../../../components/Modal";
import { Button, Loader } from "ui-2";
import { DogCat } from "@icons/DogCat";
import { MY_PETS } from "@constants/myPets";
import { Trans } from "react-i18next";
import { OK } from "@constants/index";
import { t } from "i18next";
export interface PetsAddedModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAddAnotherPet: () => void;
  onConfirm: () => void;
  isLoading: boolean;
}

export function PetsAddedModal({
  isOpen,
  onClose,
  onAddAnotherPet,
  onConfirm,
  isLoading,
}: PetsAddedModalProps) {
  const removeAndContinue = () => {
    onConfirm();
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={onClose}>
      <Modal.SideModal title={t(MY_PETS.PETS_ADDED_MODAL.TITLE)}>
        <div className="flex h-full flex-col justify-between">
          <div className="flex flex-col items-center gap-4 p-5 sm:gap-8">
            <div className="w-44 fill-[#0054A4]">{DogCat}</div>
            <div className="w-full max-w-[553px] text-base sm:text-lg">
              <Trans
                i18nKey={MY_PETS.PETS_ADDED_MODAL.DESCRIPTION}
                components={{ b: <span className="font-bold" /> }}
              />
            </div>
            {isLoading && <Loader />}
          </div>
          <div className="flex flex-col-reverse justify-end gap-4 p-5 sm:flex-row sm:border-t">
            <Button
              variant="blueText"
              onClick={onAddAnotherPet}
              disabled={isLoading}
            >
              {t(MY_PETS.PETS_ADDED_MODAL.ADD_ANOTHER_PET)}
            </Button>
            <Button
              variant="primary"
              classes="sm:min-w-[207px] whitespace-nowrap"
              onClick={removeAndContinue}
              disabled={isLoading}
            >
              {t(OK)}
            </Button>
          </div>
        </div>
      </Modal.SideModal>
    </Modal.Root>
  );
}
