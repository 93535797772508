import * as Modal from "../../../../components/Modal";
import { Button, Loader } from "ui-2";
import { useTranslation } from "react-i18next";
import { MY_PETS, OK } from "@constants/index";
import { PetAddUpdateItem } from "./PetAddUpdateItem";
import { PetChange, PetChangeType } from "../../../../types/pets";

export interface PetsUpdatesNotificationModalProps {
  petsChanges: PetChange[];
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (goToRememberedPets: boolean) => void;
  isLoading: boolean;
}

export function PetsUpdatesNotificationModal({
  petsChanges,
  isOpen,
  onClose,
  onConfirm,
  isLoading,
}: PetsUpdatesNotificationModalProps) {
  const { t } = useTranslation();

  return (
    <Modal.Root open={isOpen} onOpenChange={onClose}>
      <Modal.SideModal title={t(MY_PETS.PETS_UPDATES_NOTIFICATION_MODAL.TITLE)}>
        <div className="flex h-full flex-col justify-between">
          <div className="flex flex-col gap-4 p-5 sm:items-center sm:justify-center">
            <div className="w-full max-w-[553px] text-base">
              {t(MY_PETS.PETS_UPDATES_NOTIFICATION_MODAL.WHAT_CHANGED)}
            </div>
            <div className="flex w-full max-w-[553px] flex-col gap-4">
              {petsChanges.map((petChange) => {
                return (
                  <PetAddUpdateItem
                    key={petChange.petId}
                    petChange={petChange}
                    showNewBadge={petChange.type === PetChangeType.NEW}
                    showChanges={petChange.type === PetChangeType.UPDATED}
                  />
                );
              })}
            </div>
            <div className="w-full max-w-[553px] text-base py-2 sm:py-4">
              {t(MY_PETS.PETS_UPDATES_NOTIFICATION_MODAL.EDIT_INFO)}
            </div>
            {isLoading && <Loader />}
          </div>
          <div className="sticky bottom-0 flex flex-col-reverse justify-end gap-4 bg-white p-5 sm:flex-row sm:border-t">
            <Button
              variant="primary"
              classes="sm:min-w-[207px]"
              onClick={() => onConfirm(false)}
              disabled={isLoading}
            >
              {t(OK)}
            </Button>
          </div>
        </div>
      </Modal.SideModal>
    </Modal.Root>
  );
}
