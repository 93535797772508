import * as Modal from "../../../../components/Modal";
import { Button, Loader } from "ui-2";
import { PetChange } from "../../../../types/pets";
import { PetAddUpdateItem } from "../PetsUpdatesNotification/PetAddUpdateItem";
import { useState, useEffect } from "react";
import cn from "classnames";
import { MY_PETS } from "@constants/myPets";
import { t } from "i18next";
import { Trans } from "react-i18next";

export interface AddPetsFromSnowflakeModalProps {
  petsChanges: PetChange[];
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (selectedPetIds: string[]) => void;
  isLoading: boolean;
}

export function AddPetsFromSnowflakeModal({
  petsChanges,
  isOpen,
  onClose,
  onConfirm,
  isLoading,
}: AddPetsFromSnowflakeModalProps) {
  const [selectedPetIds, setSelectedPetIds] = useState<string[]>([]);

  useEffect(() => {
    if (isOpen && !isLoading) {
      const allPetIds = petsChanges.map((pet) => pet.petId);
      setSelectedPetIds(allPetIds);
    }
  }, [isOpen, petsChanges, isLoading]);

  const StartFromScratchMessage = ({ className }: { className?: string }) => (
    <div className={cn("w-full max-w-[553px] text-sm sm:text-base", className)}>
      <Trans
        i18nKey={MY_PETS.ADD_PETS_FROM_SNOWFLAKE_MODAL.START_FROM_SCRATCH_MESSAGE}
        components={{ b: <span className="font-bold" /> }}
      />
    </div>
  );

  return (
    <Modal.Root open={isOpen} onOpenChange={onClose}>
      <Modal.SideModal title={t(MY_PETS.ADD_PETS_FROM_SNOWFLAKE_MODAL.TITLE)}>
        <div className="flex h-full flex-col justify-between">
          <div className="flex flex-col gap-4 sm:gap-8 p-5 sm:items-center sm:justify-center">
            <div className="w-full max-w-[553px] text-base sm:text-lg text-brand-color-library-gray-900">
              {t(MY_PETS.ADD_PETS_FROM_SNOWFLAKE_MODAL.DESCRIPTION)}
              <br />
              <span className="text-sm sm:text-base text-brand-color-library-gray-800">
                {t(MY_PETS.ADD_PETS_FROM_SNOWFLAKE_MODAL.EDIT_LATER)}
              </span>
            </div>
            {isLoading && <Loader />}
            <div className="flex w-full max-w-[553px] flex-col gap-4">
              {petsChanges.map((petChange) => {
                return (
                  <PetAddUpdateItem
                    key={petChange.petId}
                    petChange={petChange}
                    selectable={true}
                    onlyNewValues={true}
                    showNewBadge={false}
                    selectedPetIds={selectedPetIds}
                    setSelectedPetIds={setSelectedPetIds}
                  />
                );
              })}
            </div>
            <StartFromScratchMessage className="hidden sm:block" />
          </div>
          <div className="sticky bottom-0 flex flex-col-reverse justify-end gap-2 sm:gap-4 bg-white p-5 sm:flex-row sm:border-t">
            <Button
              variant="dangerOutlined"
              classes="sm:min-w-[207px]"
              onClick={() => onConfirm([])}
              disabled={isLoading}
            >
              {t(MY_PETS.ADD_PETS_FROM_SNOWFLAKE_MODAL.START_FROM_SCRATCH)}
            </Button>
            <StartFromScratchMessage className="block sm:hidden" />
            <Button
              variant="primary"
              classes="sm:min-w-[207px]"
              onClick={() => onConfirm(selectedPetIds)}
              disabled={isLoading || selectedPetIds.length === 0}
            >
              {t(MY_PETS.ADD_PETS_FROM_SNOWFLAKE_MODAL.ADD_SELECTED_PETS)}
            </Button>
          </div>
        </div>
      </Modal.SideModal>
    </Modal.Root>
  );
}
