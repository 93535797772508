import { Button } from "ui-2";

export function ProductCardSkeleton({ select }: { select?: string }) {
  return (
    <div className="grid w-full grid-cols-[40%_auto] grid-rows-2 gap-x-2 gap-y-4 px-5 py-4 lg:grid-cols-1">
      <div className="bg-loading-animation row-span-2 !rounded-md max-lg:aspect-square lg:min-h-[240px]" />
      <div className="flex flex-col gap-2">
        <div className="bg-loading-animation h-[14px] w-full" />
        <div className="bg-loading-animation h-[14px] w-full lg:hidden" />
        <div className="bg-loading-animation h-[14px] w-[80%]" />
      </div>
      <div className="self-end">
        <Button
          variant="blueOutlined"
          aria-label="select product"
          type="button"
          classes="w-full pointer-events-none"
        >
          {select}
        </Button>
      </div>
    </div>
  );
}

export function ProductCardListSkeleton({ select }: { select?: string }) {
  return (
    <div className="grid md:grid-cols-2 gap-4">
      {[...Array(8)].map((_, index) => (
        <ProductCardSkeleton key={index} select={select}/>
      ))}
    </div>
  );
}
