import { MY_PETS } from "@constants/index";
import { ChevronRight } from "@icons/ChevronRight";
import { CloseIcon } from "@icons/closeIcon";
import { t } from "i18next";
export interface PetsUpdatesNotificationProps {
  onClose: () => void;
  onSeeChanges: () => void;
}

export const PetsUpdatesNotification = ({
  onClose,
  onSeeChanges,
}: PetsUpdatesNotificationProps) => {
  return (
    <div className="flex flex-col gap-4 sm:gap-6 rounded-lg border border-brand-color-library-blue-200 bg-brand-color-library-blue-100 p-4 sm:p-6">
      <div className="flex flex-col gap-2 sm:gap-4">
        <div className="flex items-center justify-between">
          <div className="sm:text-lg font-semibold text-brand-color-library-gray-800">
            {t(MY_PETS.PETS_UPDATES_NOTIFICATION.TITLE)}
          </div>
          <CloseIcon
            className="h-4 w-4 cursor-pointer fill-brand-color-library-gray-500"
            onClick={() => onClose()}
          />
        </div>
        <div className="text-sm sm:text-base font-normal text-brand-color-library-gray-900 leading-5">
          {t(MY_PETS.PETS_UPDATES_NOTIFICATION.DESCRIPTION)}
        </div>
      </div>
      <div className="flex items-center gap-1">
        <div
          className="text-brand-text-button cursor-pointer sm:text-lg font-semibold"
          onClick={() => onSeeChanges()}
        >
          {t(MY_PETS.PETS_UPDATES_NOTIFICATION.SEE_CHANGES)}
        </div>
        <ChevronRight
          className="fill-brand-text-button h-6 w-6 cursor-pointer"
          onClick={() => onSeeChanges()}
        />
      </div>
    </div>
  );
};
