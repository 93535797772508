import { PREFIX, LANG } from "../../constants/languages";
import { MY_PETS } from "../../constants/myPets";
import { t } from "i18next";
import {
  PetGenderEnum,
  PetTypesEnum,
  PetSizeEnum,
  PetFoodTypesEnum,
  PetBirthdateTypeEnum,
} from "../../types/pets";
import { Product } from "@api-calls/fetchProducts";
import { ADD_EDIT_PET } from "@constants/addEditPet";
import { FOOD_BRANDS } from "@constants/foodSelectOtherBrands";
import { PetIdentity, PetType } from "@types";
import { format } from "date-fns";

const shiftWords = () => {
  return PREFIX.indexOf(window.hph_locale || LANG.EN_US) > -1;
};

export const getPetGenderName = (genderId?: number | null) => {
  return genderId === PetGenderEnum.Male
    ? t(MY_PETS.ADD_EDIT_PET.MALE)
    : genderId === PetGenderEnum.Female
    ? t(MY_PETS.ADD_EDIT_PET.FEMALE)
    : "-";
};

export const getPetBirthday = (
  fullDateFormat: string,
  approximateFormat: string,
  petBirthday?: string | null,
  petBirthdateTypeId?: number | null
) => {
  return petBirthday
    ? format(
        new Date(petBirthday),
        petBirthdateTypeId === PetBirthdateTypeEnum.ACTUAL_DATE
          ? fullDateFormat
          : approximateFormat
      )
    : "";
};

export const getPetAdoptDate = (
  fullDateFormat: string,
  petAdoptDate?: string | null
) => {
  return petAdoptDate ? format(new Date(petAdoptDate), fullDateFormat) : "";
};

export const getPetSizeName = (
  petTypeId?: number | null,
  sizeId?: number | null
) => {
  if (petTypeId === PetTypesEnum.Dog) {
    switch (sizeId) {
      case PetSizeEnum.Small:
        return t(MY_PETS.ADD_EDIT_PET.SMALL_WEIGHT_RANGE_DOG);
      case PetSizeEnum.Medium:
        return t(MY_PETS.ADD_EDIT_PET.MEDIUM_WEIGHT_RANGE_DOG);
      case PetSizeEnum.Large:
        return t(MY_PETS.ADD_EDIT_PET.LARGE_WEIGHT_RANGE_DOG);
      default:
        return "-";
    }
  } else if (petTypeId === PetTypesEnum.Cat) {
    switch (sizeId) {
      case PetSizeEnum.Small:
        return t(MY_PETS.ADD_EDIT_PET.SMALL_WEIGHT_RANGE_CAT);
      case PetSizeEnum.Medium:
        return t(MY_PETS.ADD_EDIT_PET.MEDIUM_WEIGHT_RANGE_CAT);
      case PetSizeEnum.Large:
        return t(MY_PETS.ADD_EDIT_PET.LARGE_WEIGHT_RANGE_CAT);
      default:
        return "-";
    }
  }
  return "-";
};

export const getLifestageName = (
  petTypeId: PetTypesEnum,
  lifestageId: number | null
) => {
  if (!lifestageId) {
    return "-";
  }

  const lifestages: Record<number, string> =
    petTypeId === PetTypesEnum.Dog
      ? {
          1: t(MY_PETS.MILESTONES.PUPPY),
          2: t(MY_PETS.MILESTONES.ADULT_DOG),
          3: t(MY_PETS.MILESTONES.SENIOR_DOG),
        }
      : {
          1: t(MY_PETS.MILESTONES.KITTEN),
          2: t(MY_PETS.MILESTONES.ADULT_CAT),
          3: t(MY_PETS.MILESTONES.SENIOR_CAT),
        };

  return lifestages[lifestageId] || "-";
};

export const getPetFoodName = (
  region: string,
  dogHillsProducts: Product[],
  catHillsProducts: Product[],
  petTypeId: number,
  petFoodTypeId: number | null,
  petFoodTypeInfo: string | null
) => {
  switch (petFoodTypeId) {
    case PetFoodTypesEnum.Hills:
      if (petTypeId === PetTypesEnum.Dog) {
        return (
          dogHillsProducts?.find((product) => product.id === petFoodTypeInfo)
            ?.name || "-"
        );
      } else if (petTypeId === PetTypesEnum.Cat) {
        return (
          catHillsProducts?.find((product) => product.id === petFoodTypeInfo)
            ?.name || "-"
        );
      } else {
        return petFoodTypeInfo || "-";
      }
    case PetFoodTypesEnum.OtherBrands: {
      const brand = FOOD_BRANDS.find(
        (brand) =>
          brand.region.includes(region) &&
          brand.brandId.toString() === petFoodTypeInfo
      );
      return brand?.brandName || "-";
    }
    case PetFoodTypesEnum.Homemade:
      return t(ADD_EDIT_PET.HOME_MADE);
    default:
      return "-";
  }
};

export const getPetBreedName = (petBreedName?: string | null) => {
  return petBreedName ? t(`petBreeds.${petBreedName}`) : "-";
};

export const isTheSamePet = (existingPet: PetIdentity, newPet: PetIdentity) => {
  return (
    existingPet.petName.toLowerCase() === newPet.petName.toLowerCase() &&
    existingPet.petTypeId.toString() === newPet.petTypeId.toString()
  );
};

export const isPetAlreadyProcessed = (
  existingPet: PetType,
  newPet: PetType
) => {
  return new Date(existingPet.lastUpdatedOn) >= new Date(newPet.lastUpdatedOn);
};

/**
 * This is a workaround to avoid `react-remove-scroll-bar: cannot calculate scrollbar size because it is removed (overflow:hidden on body)` issue
 */
const showModalDelayed = (
  setState: (value: React.SetStateAction<boolean>) => void
) => {
  setTimeout(() => {
    setState(true);
  }, 10);
};

export { shiftWords, showModalDelayed };
