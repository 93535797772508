import axios from "axios";
import { API_URLS } from "../constants/apiUrls";
import { LOCALE_MAPPINGS } from "../constants/foodSelectApiMapping";

interface Payload {
  serviceStatus: ServiceStatus;
  data: Product[];
}

interface ServiceStatus {
  code: number;
}

export interface Product {
  id: string;
  name: string;
  legalName: string;
  productName: string;
  productDesc: string;
  productImage: string;
  eCommLegalName: string;
  displayName: string;
  brand: string;
  filterBrand: string;
  variant: string;
  type: string;
  method: string;
  itemForm: string;
  productForm: string;
  healthCategory: string[];
  healthConditions?: string[];
  feedingGuide?: string;
  nutritionalValue?: string;
  size?: string;
  sizeMetric?: string;
  primaryProductImageAsset?: string;
  primaryProductImageUrl: string;
  tags?: string[];
  tagsMap: TagsMap;
  lifestages?: string[];
}

interface TagsMap {
  brand?: string;
  species?: string[];
  condition?: string[];
  productFamily?: string[];
  productForm?: string[];
  lifestage?: string[];
  [key: string]: string | string[] | undefined;
}

async function fetchProducts(petType: "dog" | "cat") {
  const params = new URLSearchParams();
  params.append("species", petType);
  params.append("context", LOCALE_MAPPINGS[window.hph_locale]);

  return await axios
    .get<Payload>(
      `${process.env.REACT_APP_PET_API_URL || ""}${API_URLS.HILLS_PRODUCTS}`,
      {
        params,
      }
    )
    .then((response) =>
      (response.data.data || []).filter((p) => p.primaryProductImageUrl)
    );
}

export async function fetchDogProducts() {
  return fetchProducts("dog");
}

export async function fetchCatProducts() {
  return fetchProducts("cat");
}
