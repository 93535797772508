export const QOTW = {
  TITLE: "QOTW.QOTWCard.title",
  QUESTION: "QOTW.QOTWCard.question",
  SUBMIT: "QOTW.QOTWModal.submit",
  DONE: "QOTW.QOTWModal.done",
  CTA: "QOTW.QOTWCard.CTA",
  ALREADY_ANSWERED_TEXT: "QOTW.QOTWCard.alreadyAnsweredText",
  ERROR: "QOTW.QOTWModal.error",
  CORRECT: "QOTW.QOTWModal.correct",
  INCORRECT: "QOTW.QOTWModal.incorrect",
  PLAY_AGAIN: "QOTW.QOTWModal.playAgain",
  LEARN_MORE: "QOTW.QOTWModal.learnMore",
};

export const QOTW_DISCARDED_IDS_KEY = "QOTWDiscardedIds";
