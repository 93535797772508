import { SIDEBAR } from "../../constants/sidebar";
import { GearIcon,RememberedPetsWhite,DogCatNavHead,leftIcon } from "../../icons/";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMyPets } from "../../hooks/useMyPet";
import { ROUTES } from "../../constants/routes";
import { datalayerPush } from "../../lib/gtm";
import GTM_ACTIONS from "../../config/gtmActions";

export const Breadcrumb = (props: {
  BreadCrumbsArr: Array<string>;
  path: Array<string>;
  isMobile?: boolean;
}) => {
  const { BreadCrumbsArr, isMobile, path } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isViewRememberedPets, setIsViewRememberedPets } = useMyPets();

  const IconMapper = {
    [SIDEBAR.MY_PETS.HEADING]: <DogCatNavHead />,
    [SIDEBAR.ACCOUNT_SETTINGS.HEADING]: <GearIcon className="h-6 w-6 fill-brand-color-library-gray-000 lg:h-8 lg:w-8 lg:fill-brand-color-library-gray-800"/>,
    [SIDEBAR.MY_PETS.REMEMBERED_PETS]: <RememberedPetsWhite />,
  };

  const routeTo = (path: string) => {
    if(path === '/account-home') {
      datalayerPush(GTM_ACTIONS.LEFT_SIDE_NAV_ACCOUNT_HOME)
    }
    if (path === ROUTES.MY_PETS && isViewRememberedPets) {
      setIsViewRememberedPets?.(false);
    }
    if (isMobile && path !== ROUTES.MY_PETS) {
      navigate(`/${ROUTES.ACCOUNT_HOME}`);
    } else {
      const targetPath = path ? path : ROUTES.ACCOUNT_HOME;
      navigate(`/${targetPath}`);
    }
  };

 

  if (isMobile) {
    const label = !isViewRememberedPets
      ? t(BreadCrumbsArr[0])
      : t(BreadCrumbsArr[1]);
    const navPath = isViewRememberedPets ? ROUTES.MY_PETS : ROUTES.ACCOUNT_HOME;
    return (
      <div>
        <div className="flex flex-row items-center whitespace-nowrap bg-brand-color-library-blue-800 py-4 px-5 lg:mx-0 lg:bg-transparent lg:p-0">
          <div role="button" className="flex-start w-14" data-testid="nav-back" onClick={() => routeTo(navPath)}>
            {leftIcon}
          </div>
          <div className="flex-1 flex flex-row justify-center">
          <div className="p-1">
            {IconMapper[!isViewRememberedPets ? BreadCrumbsArr[0]:SIDEBAR.MY_PETS.REMEMBERED_PETS ]}
          </div>
          <div  className="p-1">
          <h1
            data-testid="account-settings-heading"
            className="headline5 lg:headline3 text-brand-color-library-gray-000 lg:text-brand-color-library-gray-800"
          >
            {label}
          </h1>
          </div>
          </div>
         
        </div>
      </div>
    );
  }

  return (
    <div>
      {BreadCrumbsArr.map((item, index) => {
        const appendRemCrumb = isViewRememberedPets && item === SIDEBAR.MY_PETS.HEADING;
        const showSlash = index < BreadCrumbsArr.length - 1;
        const showHiglight = BreadCrumbsArr.length !== 1 && !showSlash && !isViewRememberedPets;

        return (
          <span key={item}>
            <span role="button" onClick={() => routeTo(path[index])}>
              <span className={`${showHiglight ? `body3bold` : `body3`}`}>
                {`${t(item)}`}{" "}
              </span>
              {showSlash ? <span> / </span> : null}
            </span>
            {appendRemCrumb ? (
              <span className={``}>
                <span> / </span>
                <span className="body3bold">{`${t(
                  SIDEBAR.MY_PETS.REMEMBERED_PETS
                )}`}</span>
              </span>
            ) : null}
          </span>
        );
      })}
    </div>
  );
};

Breadcrumb.displayName = "Breadcrumb";
