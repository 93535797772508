export interface IFOOD_BRANDS {
    brandId: number;
    region: string[];
    brandName: string;
}

export const FOOD_BRANDS: IFOOD_BRANDS[] = [
    // PT-BR
    { brandId: 1, region: ["pt-br"],  brandName: "Farmina"},
    { brandId: 2, region: ["pt-br"],  brandName: "PremieR Pet"},
    { brandId: 3, region: ["pt-br"],  brandName: "Purina Pro Plan"},
    { brandId: 4, region: ["pt-br"],  brandName: "Royal Canin"},
    { brandId: 5, region: ["pt-br"],  brandName: "Outros"},
    // CA
    { brandId: 6, region: ["en-ca", "fr-ca"],  brandName: "Acana"},
    { brandId: 7, region: ["en-ca", "fr-ca"],  brandName: "Blue Buffalo"},
    { brandId: 8, region: ["en-ca", "fr-ca"],  brandName: "Iams"},
    { brandId: 9, region: ["en-ca", "fr-ca"],  brandName: "Orijen"},
    { brandId: 10, region: ["en-ca", "fr-ca"],  brandName: "Pedigree"},
    { brandId: 11, region: ["en-ca", "fr-ca"],  brandName: "Purina"},
    { brandId: 12, region: ["en-ca", "fr-ca"],  brandName: "Royal Canin"},
    { brandId: 13, region: ["en-ca"],  brandName: "Other"},
    // FR
    { brandId: 14, region: ["fr-fr"], brandName: "Dechra"},
    { brandId: 15, region: ["fr-fr"], brandName: "Edgar & Cooper"},
    { brandId: 16, region: ["fr-fr"], brandName: "Équilibre et instincts"},
    { brandId: 17, region: ["fr-fr"], brandName: "Purina / Pro Plan"},
    { brandId: 18, region: ["fr-fr"], brandName: "Royal Canin"},
    { brandId: 19, region: ["fr-fr"], brandName: "Ultima"},
    { brandId: 20, region: ["fr-fr"], brandName: "Ultra Premium Direct"},
    { brandId: 21, region: ["fr-fr"], brandName: "Virbac"},
    { brandId: 22, region: ["fr-fr", "fr-ca"],  brandName: "Autre"},
    // UK
    { brandId: 23, region: ["en-gb"], brandName: "AVA"},
    { brandId: 24, region: ["en-gb"], brandName: "Bella and Duke"},
    { brandId: 25, region: ["en-gb"], brandName: "Butternut Box"},
    { brandId: 26, region: ["en-gb"], brandName: "Eukanuba"},
    { brandId: 27, region: ["en-gb"], brandName: "James Wellbeloved"},
    { brandId: 28, region: ["en-gb"], brandName: "Lilys Kitchen"},
    { brandId: 29, region: ["en-gb"], brandName: "Purina Pro Plan"},
    { brandId: 30, region: ["en-gb"], brandName: "Royal Canin"},
    { brandId: 31, region: ["en-gb"], brandName: "Tails"},
    { brandId: 32, region: ["en-gb"], brandName: "Other"},
     // JP
     { brandId: 33, region: ["ja-jp"], brandName: "アイムス"},
     { brandId: 34, region: ["ja-jp"], brandName: "オールウェル"},
     { brandId: 35, region: ["ja-jp"], brandName: "グランデリフレシャス"},
     { brandId: 36, region: ["ja-jp"], brandName: "ドクターズ"},
     { brandId: 37, region: ["ja-jp"], brandName: "ニュートロ"},
     { brandId: 38, region: ["ja-jp"], brandName: "ピュリナ ワン"},
     { brandId: 39, region: ["ja-jp"], brandName: "プロマネージ"},
     { brandId: 40, region: ["ja-jp"], brandName: "ベストバランス"},
     { brandId: 41, region: ["ja-jp"], brandName: "ユーカヌバ"},
     { brandId: 42, region: ["ja-jp"], brandName: "ロイヤルカナン"},
     { brandId: 43, region: ["ja-jp"], brandName: "その他"},
    //US
    { brandId: 44, region: ["en-us"], brandName: "Purina"},
    { brandId: 45, region: ["en-us"], brandName: "Royal Canin"},
    { brandId: 46, region: ["en-us"], brandName: "Blue Buffalo"},
    { brandId: 47, region: ["en-us"], brandName: "Pedigree"},
    { brandId: 48, region: ["en-us"], brandName: "Iams"},
    { brandId: 49, region: ["en-us"], brandName: "Acana"},
    { brandId: 50, region: ["en-us"], brandName: "Other"},
];
