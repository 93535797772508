import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PetType } from "../../../../../types";
import { MyPets } from "../../../../components/MyPets";
import GTM_ACTIONS from "../../../../config/gtmActions";
import { ACCOUNT_HOME, PET_STATUS, ROUTES_PATH } from "../../../../constants";
import { useMyPets } from "../../../../hooks/useMyPet";
import useSearchPersistantNavigate from "../../../../hooks/useSearchPersistantNavigate";
import { DogCatSvg } from "../../../../icons/";
import { datalayerPush } from "../../../../lib/gtm";
import { useSnowFlakePets } from "@hooks/useSnowflakeData";
import { isTheSamePet } from "@lib/common";
import { PetStatusEnum } from "../../../../types/pets";

export const MyPetsWrapper = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setShowRememberedPetsLabel, isViewRememberedPets, status } =
    useMyPets();
  const searchPersistantNavigate = useSearchPersistantNavigate();
  const fetchedPetList = status?.data || [];
  const { hphPets, snowflakePets } = useSnowFlakePets();
  const { rememberedPets } = snowflakePets;

  const rememberedPetsFiltered = rememberedPets?.filter((rememberedPet) =>
    hphPets.some(
      (hphPet) =>
        isTheSamePet(rememberedPet, hphPet) &&
        hphPet.petStatusId === PetStatusEnum.ACTIVE
    )
  );

  const fetchPetList = async () => {
    const fetchedPetList = status.data || [];
    const rememberedPets = fetchedPetList.filter(
      (pet: PetType) => pet.petStatusId === PET_STATUS.REMEMBERED
    );
    setShowRememberedPetsLabel &&
      (rememberedPets?.length
        ? setShowRememberedPetsLabel(true)
        : setShowRememberedPetsLabel(false));
  };

  useEffect(() => {
    fetchPetList();
  }, []);

  const onPetClick = (petId: string) => {
    //open pet details page ?
    navigate(`${ROUTES_PATH.MY_PETS}/?petId=${petId}`, { replace: true });
  };

  const onAddPetClick = () => {
    datalayerPush(GTM_ACTIONS.ACCOUNT_HOME_ADD_A_PET);
    searchPersistantNavigate(`${ROUTES_PATH.ADD_PET}`);
  };

  const onPrimaryRouteClick = () => {
    datalayerPush(GTM_ACTIONS.ACCOUNT_HOME_MY_PETS);
    navigate(ROUTES_PATH.MY_PETS, { replace: true });
  };

  const petList = useMemo(() => {
    const activePets = fetchedPetList.filter(
      (pet: PetType) => pet.petStatusId === PET_STATUS.ACTIVE
    );
    const rememberedPets = fetchedPetList.filter(
      (pet: PetType) => pet.petStatusId === PET_STATUS.REMEMBERED
    );

    return isViewRememberedPets ? rememberedPets : activePets;
  }, [fetchedPetList, isViewRememberedPets]);

  return (
    <MyPets
      isLoading={status.isPending}
      data={petList}
      rememberedPets={rememberedPetsFiltered}
      onPetClick={onPetClick}
      onAddPetClick={onAddPetClick}
      onPrimaryRouteClick={onPrimaryRouteClick}
      label={t(ACCOUNT_HOME.MypetComponentLabel)}
      description={t(ACCOUNT_HOME.MypetComponentDescription)}
      icon={DogCatSvg}
    />
  );
};
