import { Area } from "react-easy-crop";

export enum PetStatusEnum {
  ACTIVE = 1,
  REMEMBERED = 2,
  DELETED = 3,
}

export enum PetSizeEnum {
  Small = 1,
  Medium = 2,
  Large = 3,
}

export enum PetTypesEnum {
  Dog = 1,
  Cat = 2,
}

export enum PetGenderEnum {
  Male = 1,
  Female = 2,
}

export enum PetFoodTypesEnum {
  Hills = 1,
  OtherBrands = 2,
  Homemade = 3,
}

export interface PetSizeType {
  petSizeId: PetSizeEnum;
  petTypeId: PetTypesEnum;
  petSizeName: string;
}

export interface PetTypes {
  petTypeId: PetTypesEnum;
  petTypeName: string;
}

export interface PetGender {
  petGenderId: PetGenderEnum;
  petGenderName: string;
}

export enum PetBirthdateTypeEnum {
  ACTUAL_DATE = 1,
  APPROXIMATE_DATE = 2,
}

export interface PetData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  imageToUpload?: any;
  petImage?: {
    file: File;
    rendition: Area;
  };
  petId?: string;
  petName: string;
  petNickName: string;
  petBreedId: number | null;
  petSizeId: number;
  petBirthdateTypeId: number;
  petBirthday: string;
  petAdoptDate: string;
  petGenderId: number;
  petTypeId: string;
}

export interface AddPetRequest {
  petData: PetData[];
}

export enum PetChangeType {
  NEW = "new",
  UPDATED = "updated",
}

export enum PetChangeAttributeKey {
  PET_NICK_NAME = "petNickName",
  PET_BIRTHDAY = "petBirthday",
  PET_ADOPT_DATE = "petAdoptDate",
  PET_SIZE = "petSizeId",
  PET_GENDER = "petGenderId",
  PET_BREED = "petBreedId",
  PET_FOOD_TYPE = "petFoodTypeId",
  PET_LIFE_STAGE = "petLifeStage",
  PET_VET_NAME = "petVetName",
}

export interface PetChangeAttribute {
  key: PetChangeAttributeKey;
  oldValue: string;
  newValue: string;
}

export interface PetChange {
  type: PetChangeType;
  petId: string;
  petName: string;
  petTypeId: PetTypesEnum;
  petStatusId: PetStatusEnum;
  updates: PetChangeAttribute[];
}
