import { usePetConfig } from "../../../../../hooks/usePetConfig";
import { format } from "date-fns";
import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import { BreedType } from "@types";
import React from "react";
import { FeedingGuideAccordion } from "../../../../../components/DietInfoCard/FeedingGuide/FeedingGuideAccordion";
import { MY_PETS } from "../../../../../constants/myPets";
import { IconCat, IconDog } from "ui-2";
import { getPetGenderName, getPetSizeName } from "../../../../../lib/common";

export interface ShareProfilePrintProps {
  nickname: string;
  breedId: number;
  genderId: number;
  birthdate: Date;
  petTypeId: number;
  sizeId: number;
  productId?: string;
  productName?: string;
  feedingGuide?: string;
}

export const ShareProfilePrint: React.FC<ShareProfilePrintProps> = ({
  nickname,
  breedId,
  genderId,
  birthdate,
  petTypeId,
  sizeId,
  productId,
  productName,
  feedingGuide,
}) => {
  const { isLoaded, breeds, petGender, petSizes } = usePetConfig();
  const [breed, setBreed] = useState<BreedType | null>(null);

  useEffect(() => {
    if (isLoaded?.()) {
      const breed = breeds.find(
        (b) => b.petBreedId.toString() === breedId.toString()
      );

      if (breed) {
        setBreed(breed);
      }
    }
  }, [breedId, genderId, petTypeId, sizeId, breeds, petGender, petSizes]);

  const petGenderName = useMemo(() => {
    return getPetGenderName(genderId);
  }, [genderId]);

  const petSizeName = useMemo(() => {
    return getPetSizeName(petTypeId, sizeId);
  }, [petTypeId, sizeId]);

  const defaultImage = (petTypeId: number) => {
    return (
      <button className="rounded-full focus:border-blue-500">
        <div className="rounded-full bg-[#E6E6E6] p-4">
          {petTypeId === 1 ? IconDog : IconCat}
        </div>
      </button>
    );
  };

  return (
    <div className="w-[834px] bg-white font-montserrat">
      <div className="flex flex-col gap-8 bg-gray-100">
        <div className="m-5 flex flex-col">
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAABzlBMVEX///8AVKTtGzV0dnpxc3f19fXx8fEiHx/719kgHR3tGTNrgrX9/f72tbjLy8uhr83z8/P5+fv++foaFxf6+/0OVaSbm5v4+Pjv7++2trYqYKj8/P3/+/z7+/v+9PXW1tbJycmWlZVubW3+9/f97+/c3Nza2tqlpaWenp7h4eH50dPOzs6+vr6wr6+Tk5PuPEntLj/tIDfv8vfu7u7Y3ur85uf73d/Z2dmyvdX2vcD0o6c2YacCU6ONjIxzdXloZ2dlZGRVVFTz9fnq7vX98fLm6PD87O3t7Oz86uvl5eXFz+H4zc/4yMv3wcT2srX1rbCtrKzykZWEhITxfYPvZ27uSVTuN0jtNUU1NDTd4uzq6uro6enK0eG0v9XV1NT509Srt9D4xsjBwMCEl8Bxh7dXdK9OcK70qKtAZqmnp6clXaceV6Sjo6OdnZ2ZmZnykpfyi5DxhIjwanHuYWhhYGDuRVHtKDv46evR2OW7xNrQz8/4ysygrMucqsqbp8eXpcbExMTCwsKOncL2t7q4uLj0q65GaqumpqYtXabyk5iPj4/xgYd9fHx8e3vwdHpubm7vWGJiYWHuS1ZLSkpDQkI5ODjtIzjtGDIrKSkqKCi6V5IMAAACfUlEQVRIx83VZ3PaMBgHcCkJCBGTgElwqIHQAgkbygobsvdiFLJXs/fs3nvv9W2rXC+Jr3HAftPr/86Snzv9TnreSCBZJSpJUNUrFZGJSlAlBSIi+begR8aJDnCrIC9QyM9yldYVOOUyP6g4CwHX/vz9qCh8/jgkEMg9u09mugwmAwldHnhH7SaTTPFqL/cmGpkJlQUKM92/4/GMzgVpX6AvfPd+7a2+9guBhwbm4cMjskfb5L2l9bWFqYCPLbHDtrlj2Zsf6lBNLqxDktrSTef7dYOH+YjJN/s8DmFZIM+ae7LeYYOv9iVZWh7Io/SAJxtUhWMQCgHfI3S0ENHdXIxDQcA7YNjNBdsexGPhNSHA22/e2zYFFmF9e90lAeDoTtf+EDtVD2G9ShAY7NofaHsch0LBsDlnb6+GUCgIdbyXdS5B4YDekY2tQBEgag/EoAgwODcWgyJASNG5AkUAw7PO69WcqFhuGT4PFNJZlZh7yX6jjwUiQE+ojrpwEcUDZEDvslpfKIHFarU6qPlVib7GlZB0O1e17FZGyXtVWtTISaYM+sIw39QWagQhJfMJaai3RT8vaFDjRjI1oysUaDKyoBEjJXAjDdg0gnKAUtp6QcsxuIw0jE1a43zkaOAD79xu9wEBjUWsPQXA8vW10ZlO8AGXw+HIEKBvRVrQdALcqJkBjLHkkVJc4MK4dcNGlQRpLmCsPxFCGn0pcMAFFBjXpjE28oBpMm2g1HEPp01vARcDALt5HhgxHqEAk8JFv/8XTlBPsdqm/4BTrCbtvP2wNfH3kfQ1JEpgI6OliQx+8k33kmG82zI/om05afr/fNgnJCLSXQmSlaKS/A1+nnxiFyNutwAAAABJRU5ErkJggg=="
            alt="Hills Pet Nutrition"
            className="absolute h-12 w-12"
          />
          <div className="w-full px-[92px] pt-6 pb-8 text-center text-3xl font-bold">
            {t(MY_PETS.SHARE_PET_PROFILE.PET_TITLE, { petName: nickname })}
          </div>
          <div className="flex flex-row gap-6 align-top">
            <div className="ml-9 mt-5 h-16 w-16 items-center justify-center">
              {defaultImage(petTypeId)}
            </div>
            <div className="grid grid-cols-2 gap-x-6 border-0">
              <Field
                label={t(MY_PETS.SHARE_PET_PROFILE.NICKNAME)}
                value={nickname}
              />
              <Field
                label={t(MY_PETS.SHARE_PET_PROFILE.BIRTHDATE)}
                value={format(birthdate, "MMMM d, yyyy")}
              />
              <Field
                label={t(MY_PETS.SHARE_PET_PROFILE.BREED)}
                value={
                  breed
                    ? t(`petBreeds.${breed?.petBreedName}`)
                    : t(MY_PETS.PROFILE.NO_BREED)
                }
              />
              <Field
                label={t(MY_PETS.SHARE_PET_PROFILE.SIZE)}
                value={petSizeName ?? ""}
              />
              <Field
                label={t(MY_PETS.SHARE_PET_PROFILE.GENDER)}
                value={petGenderName ?? ""}
              />
            </div>
          </div>
        </div>
      </div>

      {productId && productName && feedingGuide && (
        <div className="my-10 flex flex-col gap-4">
          <div className="ml-14 text-2xl font-bold text-gray-800">
            {t(MY_PETS.SHARE_PET_PROFILE.DIET)}
          </div>
          <div className="ml-14 flex flex-row items-start gap-6">
            <div className="h-20 w-20"></div>
            <div className="flex w-[514px] flex-col gap-3">
              <div className="flex flex-col gap-2">
                <div className="text-base font-normal text-gray-900">
                  {productName}
                </div>
                <div className="text-[14px] font-normal text-gray-600">
                  SKU: ({productId})
                </div>
              </div>
              <div className="text-base font-semibold text-gray-600">
                {t(MY_PETS.SHARE_PET_PROFILE.FEEDING_GUIDE)}
              </div>
              <hr className="border-brand-color-library-gray-300" />

              <FeedingGuideAccordion
                contentHtml={feedingGuide}
                isOpen={true}
                renderAsPdf={true}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

interface FieldProps {
  label: string;
  value: string;
}

const Field = ({ label, value }: FieldProps) => {
  return (
    <div className="flex flex-row items-center gap-2">
      <div className="text-lg text-gray-500">{label}</div>
      <div className="overflow-hidden truncate text-lg leading-10 text-gray-900">
        {value}
      </div>
    </div>
  );
};
