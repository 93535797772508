import React from "react";
import { useTranslation } from "react-i18next";
import {
  MY_PETS,
  PET_FOOD_FINDER,
  PET_FOOD_FINDER_FR_V2,
} from "../../constants";
import { ChevronRight } from "../../icons";
import { useFeatureFlagsConfig } from "../../hooks/useFeatureFlags";
import GTM_ACTIONS from "../../config/gtmActions";
import { datalayerPush } from "../../lib/gtm"; 

export function FoodQuizLink({ petType }: { petType: string }) {
  const { t } = useTranslation();
  const { foodSelectQuiz: shouldDisplayQuiz } = useFeatureFlagsConfig();
  const quizUrl = useQuizUrl(petType);

  const handleFoodQuizClick = () => {
    datalayerPush(GTM_ACTIONS.MY_PETS_DIET_CLICK_FOOD_QUIZ);
  };

  if (shouldDisplayQuiz) {
    return (
      <a
        className="flex w-full cursor-pointer items-center justify-center px-4 py-1 text-lg font-semibold no-underline sm:justify-between"
        href={quizUrl}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleFoodQuizClick}
      >
        <div className="flex items-center text-center sm:text-left">
          <span className="!text-brand-primary">
            {t(MY_PETS.DIET.FOOD_QUIZ_LINK)}
          </span>
          <span className="ml-1">
            <ChevronRight className="fill-brand-color-library-blue-500" />
          </span>
        </div>
      </a>
    );
  } else {
    return null;
  }
}

function getQuizCode({
  codes,
  language,
  petType,
}: {
  codes: Record<string, Record<string, string>>;
  language: string;
  petType: string;
}) {
  const codesByLang = codes[language] || {
    dog: "dced2731-2d81-42dd-9f5e-3d46cd60944f",
    cat: "5b84050a-34fa-4ddb-afbc-e3954a4608b3",
  };
  const code = codesByLang[petType];

  return code;
}

function useQuizUrl(petType: string): string {
  const {
    i18n: { language },
  } = useTranslation();
  const { foodSelectQuizCodes, foodSelectQuizV2 } = useFeatureFlagsConfig();

  if (foodSelectQuizV2) {
    return PET_FOOD_FINDER_FR_V2;
  }

  const code = getQuizCode({ codes: foodSelectQuizCodes, language, petType });

  return PET_FOOD_FINDER.replace("{code}", code);
}
