import { useTranslation } from "react-i18next";
import { Button } from "ui-2";
import { AddIcon, DogCat } from "../../icons";
import { MY_PETS, ROUTES_PATH } from "../../constants";
import useSearchPersistantNavigate from "../../hooks/useSearchPersistantNavigate";
import { datalayerPush } from '../../lib/gtm';
import GTM_ACTIONS from "../../config/gtmActions";

const AddPet = () => {
  const { t } = useTranslation();
  const searchPersistantNavigate = useSearchPersistantNavigate();

  const onAddPetClick = () => {
    datalayerPush(GTM_ACTIONS.MY_PETS_NO_PETS_ADD_A_PET); 
    searchPersistantNavigate(`${ROUTES_PATH.ADD_PET}`);
  };

  return (
    <div className="mt-8 flex w-full flex-col items-center lg:mt-16">
      <div className=" flex w-full flex-col items-center justify-center gap-8 lg:w-52">
        <div
          className={`flex h-[69px] w-[150px] items-center justify-center fill-brand-primary 
                      lg:h-24 lg:w-52`}
        >
          {DogCat}
        </div>
        <div className="flex flex-col gap-4">
          <div
            data-testid="noPetsLabel1"
            className=" headline4 lg:headline2 text-center"
          >
            {t(MY_PETS.NO_PETS.LABEL1)}
          </div>
          <div className="body2 lg:body1 text-center">
            {t(MY_PETS.NO_PETS.LABEL2)}
          </div>
        </div>
        <Button
          prefixIcon={<AddIcon />}
          onClick={onAddPetClick}
          classes="lg:label1 w-full"
        >
          {t(MY_PETS.NO_PETS.BUTTON_LABEL)}
        </Button>
      </div>
    </div>
  );
};

export default AddPet;
