import { fetchQOTW, FetchQOTWResponse } from "@api-calls/fetchQOTW";
import * as Modal from "@components/Modal";
import { QOTWCard } from "@components/QOTWCard";
import { QOTWForm } from "@components/QOTWForm";
import { QOTW } from "@constants/qotw";
import { Auth, useAuth } from "@hooks/useAuth";
import { useMyPets } from "@hooks/useMyPet";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { StateWithShortcuts, usePromise } from "utils";

export function QOTWWrapper() {
  return (
    <QOTWProvider>
      <QOTWComponent />
    </QOTWProvider>
  );
}

function QOTWComponent() {
  const { t } = useTranslation();
  const { isSuccess, data, refetch } = useQOTW();
  const { QOTWCardDiscarded, setQOTWCardDiscarded } = useMyPets();
  const [isFormOpen, setIsFormOpen] = useState(false);

  const handleModalStateChange = (isOpen: boolean) => {
    setIsFormOpen(isOpen);
    if (!isOpen) {
      const result = refetch();
      if (result instanceof Promise) {
        result.then(() => {
          setIsFormOpen(false);
        });
      } else {
        setIsFormOpen(false);
      }
    }
  };

  const handleCardClose = () => {
    setQOTWCardDiscarded(true);
  };

  if (!isSuccess || !data || QOTWCardDiscarded) {
    return null;
  }

  return (
    <Modal.Root
      open={isFormOpen}
      onOpenChange={handleModalStateChange}
    >
      <QOTWCard onClose={handleCardClose} />
      <Modal.SideModal
        title={t(QOTW.TITLE)}
        onClose={() => handleModalStateChange(false)}
      >
        <QOTWForm 
          questionData={data}
          onSubmitSuccess={() => {
            const result = refetch();
            if (result instanceof Promise) {
              result.then(() => {
                setIsFormOpen(false);
              });
            } else {
              setIsFormOpen(false);
            }
          }} 
        />
      </Modal.SideModal>
    </Modal.Root>
  );
}

type QOTWValue = StateWithShortcuts<FetchQOTWResponse> & {
  refetch: () => Promise<FetchQOTWResponse | undefined> | void;
};

const QOTWContext = React.createContext<QOTWValue | undefined>(undefined);
QOTWContext.displayName = "QOTWContext";

function QOTWProvider(props: React.PropsWithChildren<unknown>) {
  const [state, { exec: execFetchQOTW }] = usePromise(fetchQOTW);
  const auth: Auth = useAuth();
  const userId = auth?.user?.UID;

  const fetchQOTWForCurrentUser = React.useCallback(() => {
    if (userId) execFetchQOTW(userId);
  }, [execFetchQOTW, userId]);

  React.useEffect(() => {
    fetchQOTWForCurrentUser();
  }, [fetchQOTWForCurrentUser, userId]);

  const value = {
    ...state,
    refetch: fetchQOTWForCurrentUser,
  };

  return <QOTWContext.Provider {...props} value={value} />;
}

export function useQOTW() {
  const context = React.useContext(QOTWContext);
  if (context === undefined) {
    throw new Error("useQOTW must be used within a QOTWProvider");
  }
  return context;
}