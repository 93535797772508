import { useEffect, useState } from "react";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import { components, ControlProps, DropdownIndicatorProps, OptionProps } from "react-select";
import AsyncSelect from "react-select/async";
import { BreedType } from "../../../types";
import { ADD_EDIT_PET } from "../../constants/addEditPet";
import { usePetConfig } from "../../hooks/usePetConfig";
import { searchIcon } from "../../icons/searchIcon";

interface petBreed {
  petBreedId: number;
  petBreedName: string;
  petSizeId: number;
  petTypeId: number;
};
export default function BreedSelection({
  setPetSize,
  petTypeId,
  initialBreed,
}: {
  setPetSize: (value: BreedType | null) => void;
  petTypeId: number;
  initialBreed?: petBreed;
}) {
  const { breeds } = usePetConfig();
  const [isLoading, setIsLoading] = useState(false);
  const [petTypeBreeds, setPetTypeBreeds] = useState<BreedType[]>([]);
  const { t } = useTranslation();

  const [selectedBreed, setSelectedBreed] = useState<BreedType | null>(null);

  useEffect(() => {
    if (initialBreed) {
      const breedName = t(`petBreeds.${initialBreed.petBreedName}`);
      setSelectedBreed(
        breedName ? { ...initialBreed, petBreedName: breedName } : null
      );
    } else {
      setSelectedBreed(null);
    }
  }, [initialBreed, t]);

  useEffect(() => {
    setSelectedBreed(null);
  }, [petTypeId]);

  const filterBreeds = (inputValue: string) => {
    return petTypeBreeds.filter(
      (breed) =>
        t(`petBreeds.${breed.petBreedName}`)
          ?.toLowerCase()
          .indexOf(inputValue?.toLowerCase()) > -1
    );
  };

  const DropdownIndicator = (props: DropdownIndicatorProps) => {
    return (
      <components.DropdownIndicator {...props}>
        {searchIcon}
      </components.DropdownIndicator>
    );
  };

  const loadOptions = (
    inputValue: string,
    callback: (options: BreedType[]) => void
  ) => {
    setIsLoading(true);
    setTimeout(() => {
      const filteredBreeds = filterBreeds(inputValue);
      callback(filteredBreeds);
      setIsLoading(false);
    }, 1000);
  };

  function onInputChange(newValue: string) {
    return newValue.length > 2 ? newValue : "";
  }

  useEffect(() => {
    if (petTypeId && breeds.length) {
      const filteredBreeds = breeds
        .filter((breed) => breed.petTypeId === petTypeId)
        .sort((a, b) =>
          t(`petBreeds.${a.petBreedName}`).localeCompare(
            t(`petBreeds.${b.petBreedName}`)
          )
        );
      setPetTypeBreeds(filteredBreeds);
    } else {
      setPetTypeBreeds([]); // Clear breeds if petTypeId is not set
    }
  }, [breeds, petTypeId]);

  const customStyles = {
    dropdownIndicator: (base: object) => ({
      ...base,
      border: "none",
      padding: "5px",
      alignItems: "center",
    }),
    indicatorSeparator: (base: object) => ({
      ...base,
      display: "none",
    }),
    control: (base: object, state: ControlProps) => ({
      ...base,
      borderRadius: "4px",
      borderColor: "#C6C7CC",
      outline: state.isFocused ? "solid" : "none",
      outlineWidth: state.isFocused ? "1px" : "",
      outlineColor: state.isFocused ? "#0054A4" : "",
      outlineOffset: state.isFocused ? "1px" : "",
    }),
    option: (base: object, state: OptionProps) => ({
      ...base,
      color: "#111111",
      backgroundColor: state.isSelected ? "#DDEAFE" : "#FFFFFF",
      border: state.isFocused
        ? state.isSelected
          ? "1px solid transparent"
          : "1px solid #0054A4"
        : "1px solid transparent",
      "&:hover": {
        backgroundColor: state.isSelected ? "#DDEAFE" : "#EEF5FF",
      },
    }),
  };

  return (
    <div className="mt-2 mb-7 gap-1">
      <Field
        name="petBreed"
        id="petBreed"
        placeholder={t("addEditPet.searchBreed")}
        data-testid="breed-component"
      >
        {({ input, meta }) => (
          <>
            <AsyncSelect
              key={petTypeId}
              {...input}
              inputId="petBreedSelection"
              onChange={(breed) => {
                input.onChange(breed);
                setPetSize(breed || null);
              }}
              aria-label="breed-selection-dropdown"
              defaultOptions={petTypeBreeds}
              classNamePrefix="breed"
              loadOptions={loadOptions}
              isSearchable={true}
              isClearable={true}
              defaultValue={selectedBreed?.petBreedName}
              getOptionValue={(item: BreedType) => item.petBreedId.toString()}
              getOptionLabel={(item: BreedType) => t(`petBreeds.${item.petBreedName}`)}
              onInputChange={onInputChange}
              isLoading={isLoading}
              openMenuOnFocus={true}
              onKeyDown={(event) => {
                if (event.key === 'Enter' && !isLoading) {
                  event.preventDefault();
                }
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "#C6C7CC",
                },
              })}
              value={input.value}
              styles={customStyles}
              placeholder={
                <div className="placeholder-[#66676B]" data-testid="search-breed">
                  {t("addEditPet.searchBreed")}
                </div>
              }
              components={{ DropdownIndicator }}
              noOptionsMessage={() =>
                !petTypeId
                  ? t("validations.petTypeSelectionError")
                  : ""
              }
              aria-describedby="Breed Selection"
            />
            <div>
              {meta.error && meta.submitFailed && (
                <div className="mb-2 text-xs text-brand-error">
                  {t(ADD_EDIT_PET.PET_BREED_ERROR)}
                </div>
              )}
            </div>
          </>
        )}
      </Field>
    </div>
  );
}