import { MY_PETS } from "@constants/myPets";
import { birthdayIcon } from "@icons/birthdayIcon";
import { genderIcon } from "@icons/genderIcon";
import { weightIcon } from "@icons/weightIcon";
import { t } from "i18next";
import { FooterItem } from "./FooterItem";
import { classnames } from "utils";

const gradientClasses =
  "animate-loading-gradient rounded-full bg-gradient-to-r from-gray-400 via-gray-200 to-gray-400 bg-[length:200%_auto]";

export function BasicInfoCardSkeleton() {
  return (
    <div className="flex w-full flex-col gap-2">
      <div className=" flex flex-row items-center justify-between self-stretch">
        <div className="label2 lg:headline5">{t(MY_PETS.PROFILE.LABEL1)}</div>
      </div>
      <div className="flex w-full flex-col gap-3 rounded-lg p-0 shadow-dp2 lg:gap-2">
        <div className="w-full bg-brand-color-library-gray-100">
          <div className="flex w-full gap-4 p-4">
            <div
              className={classnames(
                "h-12 w-12 rounded-full lg:h-14 lg:w-14",
                gradientClasses
              )}
            />
            <div className="flex w-44 flex-col justify-center gap-1 lg:gap-2">
              <div className={classnames("h-4 w-20", gradientClasses)} />
              <div className={classnames("h-[10px] w-full", gradientClasses)} />
            </div>
          </div>
        </div>
        <div className="flex items-start space-x-1 p-4 text-[#66676B]">
          <FooterItem
            icon={birthdayIcon}
            content={
              <div className={classnames("h-[14px] w-20", gradientClasses)} />
            }
          />
          <FooterItem
            icon={genderIcon}
            content={
              <div className={classnames("h-[14px] w-20", gradientClasses)} />
            }
          />
          <FooterItem
            icon={weightIcon}
            content={
              <div className={classnames("h-[14px] w-20", gradientClasses)} />
            }
          />
        </div>
      </div>
    </div>
  );
}
