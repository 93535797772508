import { useCallback, useEffect, useRef } from "react";
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";
import { AppProtectedLayout, Button, Sidebar } from "ui-2";

import { CustomSidebarLinks } from "./components/CustomSidebarLinks";
import { ToastProvider } from "./components/toast";
import {
  AUTHENTICATION_STATUS,
  ROUTES,
  ROUTES_PATH,
  SIDEBAR,
} from "./constants";
import { SIDEMENU_ITEMS } from "./constants/sideMenu";
import {
  FeatureFlagProvider,
  useFeatureFlagsConfig,
} from "./hooks/useFeatureFlags";
import "./lib/api";
import gigya from "./lib/gigya";

import PetLoader from "./components/PetLoader";
import SignIn from "./components/SignIn";
import { Auth, User, useAuth } from "./hooks/useAuth";
import AccountHome from "./pages/AccountHome/";
import MyPets from "./pages/MyPets";

import RecommendedArticles from "./components/RecommendedArticles/RecommendedArticles";
import WelcomeUser from "./components/WelcomeUser";
import { PetConfigProvider } from "./hooks/usePetConfig";
import AccountSettings from "./pages/AccountSettings";
import { ChangePasswordModal } from "./pages/AccountSettings/ChangePassword";
import { CommunicationPreferencesModal } from "./pages/AccountSettings/CommunicationPreferences";
import { EditBasicInfoModal } from "./pages/AccountSettings/EditBasicInfo";
import { AddPetModal } from "./pages/MyPets/AddPet";
import { EditPetModal } from "./pages/MyPets/EditPet";
import { MypetsPetsProvider } from "./hooks/useMyPet";
import { RememberedPets } from "./pages/RememberedPets";
import { HideModalWrapper } from "./pages/MyPets/components/PetsNameHeadingWrapper/components/HideModalWrapper";
import { DeleteModalWrapper } from "./pages/MyPets/components/PetsNameHeadingWrapper/components/DeleteModalWrapper";
import { Breadcrumb } from "./components/Breadcrumb";
import { ProductsProvider } from "./pages/MyPets/context/ProductsContext";
import { SnowflakeDataProvider } from "@hooks/useSnowflakeData";


export default function App() {
  const restrictLoginEventCall = useRef(false);
  const restrictDashboardNavigation = useRef(false);
  const auth: Auth = useAuth();
  const navigate = useNavigate();
  const { snowFlake } = useFeatureFlagsConfig();

  //If user is already authenticated navigate to Dashboard page
  if (
    auth.status === AUTHENTICATION_STATUS.AUTHENTICATED &&
    (location.hash === "#/" ||
      location.hash === "" ||
      location.hash === "#/dashboard") &&
    localStorage.getItem("gigyaToken") !== null
  ) {
    if (restrictDashboardNavigation.current) {
      restrictDashboardNavigation.current = false;
      navigate(ROUTES_PATH.ACCOUNT_SETTINGS, { replace: true });
    } else {
      navigate(ROUTES_PATH.ACCOUNT_HOME, { replace: true });
    }
  }

  const onLogin = useCallback(
    (response: User) => {
      auth.generateAuthToken?.(response);
    },
    [auth]
  );

  const onLogout = useCallback(() => {
    auth.afterLogout?.();
  }, [auth]);

  useEffect(() => {
    if (
      gigya !== undefined &&
      !restrictLoginEventCall.current &&
      auth?.inAppAuth
    ) {
      gigya.accounts.addEventHandlers({
        onLogin: onLogin,
        onLogout: onLogout,
      });
      restrictLoginEventCall.current = true;
    }
  }, [onLogin, onLogout]);

  const customEditProfileDispatch = (event: CustomEvent) => {
    if (event.detail === "edit-profile") {
      restrictDashboardNavigation.current = true;
    }
  };

  useEffect(() => {
    window.addEventListener("hph", customEditProfileDispatch as EventListener);
    return () => {
      window.removeEventListener(
        "scroll",
        customEditProfileDispatch as EventListener
      );
    };
  }, []);

  const handleLogout = () => {
    auth.logout && auth.logout();
  };

  const inAppAuth = process.env.REACT_APP_IN_APP_AUTH?.toLowerCase() === "true";
  if (auth.status !== AUTHENTICATION_STATUS.AUTHENTICATED && !inAppAuth) {
    return <PetLoader />;
  }

  return (
    <ToastProvider>
      <ProductsProvider>
        <PetConfigProvider>
          <FeatureFlagProvider>
          <SnowflakeDataProvider isEnabled={snowFlake}>
            <MypetsPetsProvider>
              {auth?.inAppAuth && (
                <div className="absolute right-0 m-2 rounded py-2 px-4 z-10">
                  <Button
                    variant="primary"
                    size="sm"
                    type="button"
                    onClick={() => handleLogout()}
                  >
                    Logout
                  </Button>
                </div>
              )}
              <Routes>
                <Route
                  path={ROUTES.ACCOUNT_HOME}
                  element={
                    <>
                      <div className="block lg:hidden">
                        <WelcomeUser user={auth.user?.profile || null} />
                      </div>
                      <AppProtectedLayout
                        content={<AccountHome />}
                        sideMenu={
                          <Sidebar
                            items={SIDEMENU_ITEMS}
                            render={(data, isSubcat, index) => (
                              <CustomSidebarLinks
                                item={data}
                                key={index}
                                isSubcategory={isSubcat}
                              />
                            )}
                          />
                        }
                        upperContent={
                          <Breadcrumb
                            BreadCrumbsArr={[SIDEBAR.ACCOUNT_HOME]}
                            path={[ROUTES.ACCOUNT_HOME]}
                          />
                        }
                        lowerContent={<RecommendedArticles />}
                      />
                    </>
                  }
                />
                  <Route
                    path={ROUTES.MY_PETS}
                    element={
                      <>
                        <div className="block lg:hidden">
                          <Breadcrumb
                            BreadCrumbsArr={[
                              SIDEBAR.MY_PETS.HEADING,
                              SIDEBAR.MY_PETS.REMEMBERED_PETS,
                            ]}
                            path={[ROUTES.MY_PETS, ROUTES.REMEMBERED_PETS]}
                            isMobile={true}
                          />
                        </div>
                        <AppProtectedLayout
                          content={
                            <>
                              <MyPets />
                              <Outlet />
                            </>
                          }
                          sideMenu={
                            <Sidebar
                              items={SIDEMENU_ITEMS}
                              render={(data, isSubcat, index) => (
                                <CustomSidebarLinks
                                  item={data}
                                  isSubcategory={isSubcat}
                                  key={index}
                                />
                              )}
                            />
                          }
                          upperContent={
                            <Breadcrumb
                              BreadCrumbsArr={[
                                SIDEBAR.ACCOUNT_HOME,
                                SIDEBAR.MY_PETS.HEADING,
                              ]}
                              path={[ROUTES.ACCOUNT_HOME, ROUTES.MY_PETS]}
                            />
                          }
                          lowerContent={<RememberedPets />}
                        />
                      </>
                    }
                  >
                    <Route path={ROUTES.ADD_PET} element={<AddPetModal />} />
                    <Route
                      path={`${ROUTES.EDIT_PET}`}
                      element={<EditPetModal />}
                    />
                    <Route
                      path={`${ROUTES.HIDE_PET}`}
                      element={<HideModalWrapper />}
                    />
                    <Route
                      path={`${ROUTES.DELETE_PET}`}
                      element={<DeleteModalWrapper />}
                    />
                  </Route>
                  <Route
                    path={ROUTES_PATH.ACCOUNT_SETTINGS}
                    element={
                      <>
                        <div className="block lg:hidden ">
                          <Breadcrumb
                            BreadCrumbsArr={[SIDEBAR.ACCOUNT_SETTINGS.HEADING]}
                            path={[ROUTES.ACCOUNT_SETTINGS]}
                            isMobile={true}
                          />
                        </div>
                        <AppProtectedLayout
                          content={
                            <>
                              <AccountSettings />
                              <Outlet />
                            </>
                          }
                          sideMenu={
                            <Sidebar
                              items={SIDEMENU_ITEMS}
                              render={(data, isSubcat, index) => (
                                <CustomSidebarLinks
                                  item={data}
                                  key={index}
                                  isSubcategory={isSubcat}
                                />
                              )}
                            />
                          }
                          upperContent={
                            <Breadcrumb
                              BreadCrumbsArr={[
                                SIDEBAR.ACCOUNT_HOME,
                                SIDEBAR.ACCOUNT_SETTINGS.HEADING,
                              ]}
                              path={[
                                ROUTES.ACCOUNT_HOME,
                                ROUTES.ACCOUNT_SETTINGS,
                              ]}
                            />
                          }
                        />
                      </>
                    }
                  >
                    <Route
                      path={ROUTES.CHANGE_PASSWORD}
                      element={<ChangePasswordModal />}
                    />
                    <Route
                      path={ROUTES.COMMUNICATION_PREFERENCES}
                      element={<CommunicationPreferencesModal />}
                    />
                    <Route
                      path={ROUTES.EDIT_BASIC_INFO}
                      element={<EditBasicInfoModal />}
                    />
                  </Route>
                  <Route
                    index
                    path="*"
                    element={auth?.inAppAuth ? <SignIn /> : <PetLoader />}
                  />
                  <Route
                    path={ROUTES.COMMUNICATION_PREFERENCES}
                    element={<CommunicationPreferencesModal />}
                  />
                  <Route
                    path={ROUTES.EDIT_BASIC_INFO}
                    element={<EditBasicInfoModal />}
                  />
                <Route
                  index
                  path="*"
                  element={auth?.inAppAuth ? <SignIn /> : <PetLoader />}
                />
                {auth.isTokenGenerating && <PetLoader />}
              </Routes>
            </MypetsPetsProvider>
            </SnowflakeDataProvider>
          </FeatureFlagProvider>
        </PetConfigProvider>
      </ProductsProvider>
    </ToastProvider>
  );
}
