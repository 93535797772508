import { useEffect, useMemo, useRef, useState } from "react";
import { useFetchNotifications } from "../../api-calls/useFetchNotifications";
import AccountSettings from "../../components/AccountSettings";
import {
  ADD_EDIT_PET,
  NOTIFICATION,
  PET_HIDE_REASONS,
  ROUTES_PATH,
} from "../../constants";
import { useFeatureFlagsConfig } from "../../hooks/useFeatureFlags";
import { MyPetsWrapper } from "./components/MyPetsWrapper";
import RebatePopupWrapper from "./components/RebatePopupWrapper";
import WelcomeUserWrapper from "./components/WelcomeUserWrapper";
import { QOTWWrapper } from "./components/QOTWWrapper";
import { PetsUpdatesNotification } from "./components/PetsUpdatesNotification";
import {
  PetChange,
  PetChangeAttribute,
  PetChangeAttributeKey,
  PetChangeType,
  PetData,
  PetSizeEnum,
  PetStatusEnum,
} from "../../types/pets";
import { PetsUpdatesNotificationModal } from "./components/PetsUpdatesNotification/PetsUpdatesNotificationModal";
import { AddPetsFromSnowflakeModal } from "./components/AddPetsFromSnowflakeModal";
import { useSnowFlakePets } from "@hooks/useSnowflakeData";
import { RemovePetsConfirmModal } from "./components/AddPetsFromSnowflakeModal/RemovePetsConfirmModal";
import { StartFromScratchModal } from "./components/AddPetsFromSnowflakeModal/StartFromScratchModal";
import { PetsAddedModal } from "./components/AddPetsFromSnowflakeModal/PetsAddedModal";
import {
  getLifestageName,
  getPetAdoptDate,
  getPetBirthday,
  getPetBreedName,
  getPetFoodName,
  getPetGenderName,
  getPetSizeName,
  isTheSamePet,
  showModalDelayed,
} from "@lib/common";
import useSearchPersistantNavigate from "@hooks/useSearchPersistantNavigate";
import { usePetConfig } from "@hooks/usePetConfig";
import { useProducts } from "@pages/MyPets/context/ProductsContext";
import { useToastConfig } from "@components/toast";
import { useTranslation } from "react-i18next";
import { useSetAddPet } from "@api-calls/useSetAddPet";
import { useMyPets } from "@hooks/useMyPet";
import { useSetUpdatePet } from "@api-calls/useSetUpdatePet";
import { PetType } from "@types";
import { setPetStatus } from "@api-calls/useUpdatePetStatus";
import { usePromise } from "utils";

const AccountHome = () => {
  const { fetchNotificationData, data } = useFetchNotifications(
    NOTIFICATION.REBATE_NOTIFICTION_TYPE
  );
  const { breeds } = usePetConfig();
  const { rebateNotification, QotwEnabled } = useFeatureFlagsConfig();
  const {
    hphPets,
    snowflakePets,
    isSfDataLoading,
    fetchSnowflakePets,
    petsUpdatesNotificationDiscarded,
    setPetsUpdatesNotificationDiscarded,
  } = useSnowFlakePets();
  const { newPets, updatedPets } = snowflakePets;
  const { refetchPetList } = useMyPets();

  const initialized = useRef(false);
  const abortControllerRef = useRef<AbortController | null>(null);
  const [
    isPetsUpdatesNotificationModalOpen,
    setIsPetsUpdatesNotificationModalOpen,
  ] = useState(false);
  const [isAddPetsFromSnowflakeModalOpen, setIsAddPetsFromSnowflakeModalOpen] =
    useState(false);
  const [isRemovePetsModalOpen, setIsRemovePetsModalOpen] = useState(false);
  const [petsToRemove, setPetsToRemove] = useState<PetChange[]>([]);
  const [isStartFromScratchModalOpen, setIsStartFromScratchModalOpen] =
    useState(false);
  const [isPetsAddedModalOpen, setIsPetsAddedModalOpen] = useState(false);
  const [hideNotification, setNotificationStatus] = useState(false);
  const searchPersistantNavigate = useSearchPersistantNavigate();
  const { fullDateFormat, approximateFormat } = useFeatureFlagsConfig();
  const dogHillsProducts = useProducts({ pet: "dog" });
  const catHillsProducts = useProducts({ pet: "cat" });
  const region = window?.hph_locale;
  const { showToast } = useToastConfig();
  const { t } = useTranslation();
  const { setAddPetData } = useSetAddPet({
    petData: [],
  });
  const { setUpdatePetData } = useSetUpdatePet({
    petData: [],
  });
  const [, { exec: setPetStatusData }] = usePromise(setPetStatus);

  const getPetsToUpdate = (updatedPets: PetType[]) => {
    return updatedPets
      ?.filter((updatedPet) =>
        hphPets.some((hphPet) => isTheSamePet(hphPet, updatedPet))
      )
      .map((updatedPet) => {
        const matchingPet = hphPets.find((hphPet) =>
          isTheSamePet(hphPet, updatedPet)
        );
        return {
          ...matchingPet,
          petName: matchingPet?.petName || "",
          petNickName: updatedPet.petNickName || matchingPet?.petNickName || "",
          petBirthday: updatedPet.petBirthday || matchingPet?.petBirthday || "",
          petAdoptDate:
            updatedPet.petAdoptDate || matchingPet?.petAdoptDate || "",
          petSizeId: updatedPet.petSizeId || matchingPet?.petSizeId || 0,
          petGenderId: updatedPet.petGenderId || matchingPet?.petGenderId || 0,
          petBreedId: updatedPet.petBreedId || matchingPet?.petBreedId || 0,
          petFoodTypeId:
            updatedPet.petFoodTypeId || matchingPet?.petFoodTypeId || 0,
          petFoodTypeInfo:
            updatedPet.petFoodTypeInfo || matchingPet?.petFoodTypeInfo || "",
          petLifeStageId:
            updatedPet.petLifeStageId || matchingPet?.petLifeStageId || 0,
          petLifeStage:
            updatedPet.petLifeStage || matchingPet?.petLifeStage || "",
          petVetName: updatedPet.petVetName || matchingPet?.petVetName || "",
          petBirthdateTypeId:
            updatedPet.petBirthdateTypeId ||
            matchingPet?.petBirthdateTypeId ||
            0,
          petTypeId: matchingPet?.petTypeId.toString() || "",
          petImage: undefined,
        };
      });
  };

  const getPetsToAdd = (newPets: PetType[], petsToRemove: PetChange[]) => {
    return newPets
      ?.filter(
        (newPet) => !hphPets.some((hphPet) => isTheSamePet(hphPet, newPet))
      )
      .map((pet) => {
        const petStatusId = petsToRemove.some(
          (petToRemove) =>
            isTheSamePet(petToRemove, pet) &&
            petToRemove.petStatusId === PetStatusEnum.ACTIVE
        )
          ? PetStatusEnum.DELETED
          : pet.petStatusId;

        return {
          ...pet,
          petStatusId,
        };
      });
  };

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (
      !isSfDataLoading &&
      petsToAddChanges.length > 0 &&
      hphPets.length === 0
    ) {
      showModalDelayed(setIsAddPetsFromSnowflakeModalOpen);
    }
  }, [isSfDataLoading]);

  useEffect(() => {
    abortControllerRef.current && abortControllerRef.current.abort();
    if (!initialized.current) {
      initialized.current = true;
      fetchNotificationData();
    }
    return () => {
      abortControllerRef.current && abortControllerRef.current.abort();
    };
  }, []);

  const onSeeChanges = () => {
    setIsPetsUpdatesNotificationModalOpen(true);
  };

  const petsToAddChanges = useMemo<PetChange[]>(() => {
    const changes: PetChange[] = [];

    newPets
      ?.filter(
        (newPet) =>
          newPet.petStatusId === PetStatusEnum.ACTIVE &&
          !hphPets.some((hphPet) => isTheSamePet(hphPet, newPet))
      )
      .forEach((newPet) => {
        const breed = breeds.find(
          (b) => b.petBreedId.toString() === newPet.petBreedId?.toString()
        );

        const updates: PetChangeAttribute[] = [];

        if (newPet.petNickName) {
          updates.push({
            key: PetChangeAttributeKey.PET_NICK_NAME,
            oldValue: "",
            newValue: newPet.petNickName,
          });
        }

        if (newPet.petBirthday) {
          updates.push({
            key: PetChangeAttributeKey.PET_BIRTHDAY,
            oldValue: "",
            newValue: getPetBirthday(
              fullDateFormat,
              approximateFormat,
              newPet.petBirthday,
              newPet.petBirthdateTypeId
            ),
          });
        }

        if (newPet.petAdoptDate) {
          updates.push({
            key: PetChangeAttributeKey.PET_ADOPT_DATE,
            oldValue: "",
            newValue: getPetAdoptDate(fullDateFormat, newPet.petAdoptDate),
          });
        }

        if (newPet.petSizeId) {
          updates.push({
            key: PetChangeAttributeKey.PET_SIZE,
            oldValue: "",
            newValue: getPetSizeName(newPet.petTypeId, newPet.petSizeId),
          });
        }

        if (newPet.petGenderId) {
          updates.push({
            key: PetChangeAttributeKey.PET_GENDER,
            oldValue: "",
            newValue: getPetGenderName(newPet.petGenderId),
          });
        }

        if (newPet.petBreedId && breed) {
          updates.push({
            key: PetChangeAttributeKey.PET_BREED,
            oldValue: "",
            newValue: getPetBreedName(breed?.petBreedName),
          });
        }

        if (newPet.petFoodTypeId) {
          updates.push({
            key: PetChangeAttributeKey.PET_FOOD_TYPE,
            oldValue: "",
            newValue: getPetFoodName(
              region,
              dogHillsProducts?.data || [],
              catHillsProducts?.data || [],
              newPet.petTypeId,
              newPet.petFoodTypeId,
              newPet.petFoodTypeInfo
            ),
          });
        }

        if (newPet.petLifeStageId && newPet.petTypeId) {
          updates.push({
            key: PetChangeAttributeKey.PET_LIFE_STAGE,
            oldValue: "",
            newValue: getLifestageName(newPet.petTypeId, newPet.petLifeStageId),
          });
        }

        if (newPet.petVetName) {
          updates.push({
            key: PetChangeAttributeKey.PET_VET_NAME,
            oldValue: "",
            newValue: newPet.petVetName,
          });
        }

        changes.push({
          type: PetChangeType.NEW,
          petId: newPet.petId,
          petName: newPet.petName,
          petTypeId: newPet.petTypeId,
          petStatusId: newPet.petStatusId || PetStatusEnum.ACTIVE,
          updates,
        });
      });

    return changes;
  }, [hphPets, newPets]);

  const petsToUpdateChanges = useMemo<PetChange[]>(() => {
    const changes: PetChange[] = [];

    updatedPets?.forEach((updatedPet) => {
      const pet = hphPets.find(
        (hphPet) =>
          isTheSamePet(hphPet, updatedPet) &&
          hphPet.petStatusId === PetStatusEnum.ACTIVE
      );

      if (pet) {
        const updates: PetChangeAttribute[] = [];

        if (
          updatedPet.petNickName &&
          updatedPet.petNickName !== pet.petNickName
        ) {
          updates.push({
            key: PetChangeAttributeKey.PET_NICK_NAME,
            oldValue: pet.petNickName || "",
            newValue: updatedPet.petNickName,
          });
        }

        if (
          (updatedPet.petBirthday &&
            updatedPet.petBirthday !== pet.petBirthday) ||
          (updatedPet.petBirthdateTypeId &&
            updatedPet.petBirthdateTypeId !== pet.petBirthdateTypeId)
        ) {
          updates.push({
            key: PetChangeAttributeKey.PET_BIRTHDAY,
            oldValue: getPetBirthday(
              fullDateFormat,
              approximateFormat,
              pet.petBirthday,
              pet.petBirthdateTypeId
            ),
            newValue: getPetBirthday(
              fullDateFormat,
              approximateFormat,
              updatedPet.petBirthday,
              updatedPet.petBirthdateTypeId
            ),
          });
        }

        if (
          updatedPet.petAdoptDate &&
          updatedPet.petAdoptDate !== pet.petAdoptDate
        ) {
          updates.push({
            key: PetChangeAttributeKey.PET_ADOPT_DATE,
            oldValue: getPetAdoptDate(fullDateFormat, pet.petAdoptDate),
            newValue: getPetAdoptDate(fullDateFormat, updatedPet.petAdoptDate),
          });
        }

        if (updatedPet.petSizeId && updatedPet.petSizeId !== pet.petSizeId) {
          updates.push({
            key: PetChangeAttributeKey.PET_SIZE,
            oldValue: getPetSizeName(pet.petTypeId, pet.petSizeId),
            newValue: getPetSizeName(
              updatedPet.petTypeId,
              updatedPet.petSizeId
            ),
          });
        }

        if (
          updatedPet.petGenderId &&
          updatedPet.petGenderId !== pet.petGenderId
        ) {
          updates.push({
            key: PetChangeAttributeKey.PET_GENDER,
            oldValue: getPetGenderName(pet.petGenderId),
            newValue: getPetGenderName(updatedPet.petGenderId),
          });
        }

        if (updatedPet.petBreedId && updatedPet.petBreedId !== pet.petBreedId) {
          const oldBreed = breeds.find(
            (b) => b.petBreedId.toString() === pet.petBreedId?.toString()
          );
          const newBreed = breeds.find(
            (b) => b.petBreedId.toString() === updatedPet.petBreedId?.toString()
          );
          updates.push({
            key: PetChangeAttributeKey.PET_BREED,
            oldValue: getPetBreedName(oldBreed?.petBreedName),
            newValue: getPetBreedName(newBreed?.petBreedName),
          });
        }

        if (
          updatedPet.petFoodTypeId &&
          updatedPet.petFoodTypeId !== pet.petFoodTypeId
        ) {
          updates.push({
            key: PetChangeAttributeKey.PET_FOOD_TYPE,
            oldValue: getPetFoodName(
              region,
              dogHillsProducts?.data || [],
              catHillsProducts?.data || [],
              pet.petTypeId,
              pet.petFoodTypeId,
              pet.petFoodTypeInfo
            ),
            newValue: getPetFoodName(
              region,
              dogHillsProducts?.data || [],
              catHillsProducts?.data || [],
              updatedPet.petTypeId,
              updatedPet.petFoodTypeId,
              updatedPet.petFoodTypeInfo
            ),
          });
        }

        if (
          updatedPet.petLifeStageId &&
          updatedPet.petLifeStageId !== pet.petLifeStageId
        ) {
          updates.push({
            key: PetChangeAttributeKey.PET_LIFE_STAGE,
            oldValue: getLifestageName(pet.petTypeId, pet.petLifeStageId),
            newValue: getLifestageName(
              updatedPet.petTypeId,
              updatedPet.petLifeStageId
            ),
          });
        }

        if (updatedPet.petVetName && updatedPet.petVetName !== pet.petVetName) {
          updates.push({
            key: PetChangeAttributeKey.PET_VET_NAME,
            oldValue: pet.petVetName || "-",
            newValue: updatedPet.petVetName,
          });
        }

        if (updates.length > 0) {
          changes.push({
            type: PetChangeType.UPDATED,
            petId: pet.petId,
            petName: pet.petName,
            petTypeId: pet.petTypeId,
            petStatusId: pet.petStatusId || PetStatusEnum.ACTIVE,
            updates,
          });
        }
      }
    });

    return changes;
  }, [hphPets, updatedPets]);

  const petsToAddOrUpdateChanges = useMemo<PetChange[]>(() => {
    return [...petsToAddChanges, ...petsToUpdateChanges];
  }, [petsToAddChanges, petsToUpdateChanges]);

  const onAddPetsFromSnowflake = async (selectedPetIds: string[]) => {
    if (selectedPetIds.length === 0) {
      showModalDelayed(setIsStartFromScratchModalOpen);
      return;
    }

    const newPetsToRemove = petsToAddChanges.filter(
      (petChange) =>
        petChange.type === PetChangeType.NEW &&
        !selectedPetIds.includes(petChange.petId)
    );

    if (newPetsToRemove.length > 0) {
      setPetsToRemove(newPetsToRemove);
      showModalDelayed(setIsRemovePetsModalOpen);
    } else {
      const pets = getPetsToAdd(newPets, newPetsToRemove).map((pet) => ({
        ...pet,
        petNickName: pet.petNickName || "",
        petBirthday: pet.petBirthday || "",
        petAdoptDate: pet.petAdoptDate || "",
        petGenderId: pet.petGenderId || 0,
        petSizeId: pet.petSizeId || PetSizeEnum.Small,
        petTypeId: pet.petTypeId,
        petImage: undefined,
      }));

      await callAddPetsApi(pets, () => {
        setIsAddPetsFromSnowflakeModalOpen(false);
        showModalDelayed(setIsPetsAddedModalOpen);
      });
    }
  };

  const onGoBackToAddPetsFromSnowflake = () => {
    setIsRemovePetsModalOpen(false);
    showModalDelayed(setIsAddPetsFromSnowflakeModalOpen);
  };

  const onConfirmRemovePets = async () => {
    const pets = getPetsToAdd(newPets, petsToRemove).map((pet) => ({
      ...pet,
      petNickName: pet.petNickName || "",
      petBirthday: pet.petBirthday || "",
      petAdoptDate: pet.petAdoptDate || "",
      petGenderId: pet.petGenderId || 0,
      petSizeId: pet.petSizeId || PetSizeEnum.Small,
      petTypeId: pet.petTypeId,
      petImage: undefined,
    }));

    await callAddPetsApi(pets, () => {
      showModalDelayed(setIsPetsAddedModalOpen);
      setIsRemovePetsModalOpen(false);
    });
  };

  const onAddAnotherPet = () => {
    setIsPetsAddedModalOpen(false);
    refetchPetList();
    searchPersistantNavigate(`${ROUTES_PATH.ADD_PET}`);
  };

  const onPetsAddedConfirm = () => {
    setIsPetsAddedModalOpen(false);
    searchPersistantNavigate(`${ROUTES_PATH.MY_PETS}`);
  };

  const onStartFromScratchGoBack = () => {
    setIsStartFromScratchModalOpen(false);
    showModalDelayed(setIsAddPetsFromSnowflakeModalOpen);
  };

  const callAddPetsApi = async (pets: PetType[], onSuccess: () => void) => {
    try {
      setIsLoading(true);

      const requests = pets.map(
        (pet) =>
          ({
            petName: pet.petName,
            petNickName: pet.petNickName,
            petBreedId: pet.petBreedId,
            petSizeId: pet.petSizeId,
            petBirthdateTypeId: pet.petBirthdateTypeId,
            petBirthday: pet.petBirthday,
            petAdoptDate: pet.petAdoptDate,
            petGenderId: pet.petGenderId,
            petTypeId: pet.petTypeId.toString(),
          } as PetData)
      );

      const addResponses = await Promise.all(
        requests.map((request) => setAddPetData({ petData: [request] }))
      );

      const addedPets = addResponses.map(
        (response) => response?.data?.data?.[0] as PetType
      );

      const updateStatusResponses = await Promise.all(
        pets
          .filter((pet) => pet.petStatusId !== PetStatusEnum.ACTIVE)
          .map((pet) => {
            const petId =
              addedPets.find((addedPet) => isTheSamePet(addedPet, pet))
                ?.petId || "";
            return setPetStatusData({
              petId,
              petStatusId:
                pet.petStatusId === PetStatusEnum.REMEMBERED
                  ? PetStatusEnum.REMEMBERED
                  : PetStatusEnum.DELETED,
              petRemovalReason:
                pet.petStatusId === PetStatusEnum.DELETED
                  ? PET_HIDE_REASONS.DECEASED
                  : PET_HIDE_REASONS.DELETED,
            });
          })
      );

      const allSuccess =
        addResponses.every(
          (response) => response?.data?.serviceStatus.code === 200
        ) &&
        updateStatusResponses.every(
          (response) => response?.data?.serviceStatus.code === 200
        );

      if (allSuccess) {
        fetchSnowflakePets();
        refetchPetList();
        onSuccess();
      } else {
        showToast({
          description: t(ADD_EDIT_PET.COMMON_ERROR_WARNING),
          type: "error",
        });
      }
    } catch (error) {
      console.log(error);
      showToast({
        description: t(ADD_EDIT_PET.COMMON_ERROR_WARNING),
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const callUpdatePetsApi = async (
    updatePets: PetData[],
    onSuccess: () => void
  ) => {
    try {
      setIsLoading(true);

      const updateResponses = await Promise.all(
        updatePets.map((pet) =>
          setUpdatePetData({
            petData: [
              {
                petId: pet.petId || "",
                petName: pet.petName,
                petNickName: pet.petNickName,
                petBreedId: pet.petBreedId,
                petSizeId: pet.petSizeId,
                petBirthdateTypeId: pet.petBirthdateTypeId,
                petBirthday: pet.petBirthday,
                petAdoptDate: pet.petAdoptDate,
                petGenderId: pet.petGenderId,
                petTypeId: pet.petTypeId.toString(),
              },
            ],
          })
        )
      );

      const allSuccess = updateResponses.every(
        (response) => response?.data?.serviceStatus.code === 200
      );

      if (allSuccess) {
        fetchSnowflakePets();
        refetchPetList();
        onSuccess();
        searchPersistantNavigate(`${ROUTES_PATH.MY_PETS}`);
      } else {
        showToast({
          description: t(ADD_EDIT_PET.COMMON_ERROR_WARNING),
          type: "error",
        });
      }
    } catch (error) {
      showToast({
        description: t(ADD_EDIT_PET.COMMON_ERROR_WARNING),
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onStartFromScratchConfirm = async () => {
    const newPetsToRemove = newPets
      ?.filter((pet) => pet.petStatusId === PetStatusEnum.ACTIVE)
      ?.map((pet) => ({
        type: PetChangeType.NEW,
        petId: pet.petId,
        petName: pet.petName,
        petTypeId: pet.petTypeId,
        petStatusId: pet.petStatusId || PetStatusEnum.ACTIVE,
        updates: [],
      }));

    setPetsToRemove(newPetsToRemove);

    const pets = getPetsToAdd(newPets, newPetsToRemove).map((pet) => ({
      ...pet,
      petNickName: pet.petNickName || "",
      petBirthday: pet.petBirthday || "",
      petAdoptDate: pet.petAdoptDate || "",
      petGenderId: pet.petGenderId || 0,
      petSizeId: pet.petSizeId || PetSizeEnum.Small,
      petTypeId: pet.petTypeId,
      petImage: undefined,
    }));

    await callAddPetsApi(pets, () => {
      setIsStartFromScratchModalOpen(false);
      searchPersistantNavigate(`${ROUTES_PATH.MY_PETS}`);
    });
  };

  const onPetsUpdatesNotificationConfirm = async () => {
    const addPets = getPetsToAdd(newPets || [], []).map((pet) => ({
      ...pet,
      petNickName: pet.petNickName || "",
      petBirthday: pet.petBirthday || "",
      petAdoptDate: pet.petAdoptDate || "",
      petGenderId: pet.petGenderId || 0,
      petSizeId: pet.petSizeId || PetSizeEnum.Small,
      petTypeId: pet.petTypeId,
      petImage: undefined,
    }));

    const updatePets = getPetsToUpdate(updatedPets || []).map((pet) => ({
      ...pet,
      petNickName: pet.petNickName || "",
      petBirthday: pet.petBirthday || "",
      petAdoptDate: pet.petAdoptDate || "",
      petGenderId: pet.petGenderId || 0,
      petSizeId: pet.petSizeId || PetSizeEnum.Small,
      petTypeId: pet.petTypeId,
      petImage: undefined,
    }));

    await Promise.all([
      new Promise((resolve) => callAddPetsApi(addPets, () => resolve(true))),
      new Promise((resolve) =>
        callUpdatePetsApi(updatePets, () => resolve(true))
      ),
    ]);

    setIsPetsUpdatesNotificationModalOpen(false);
  };

  return (
    <div className="px-5 lg:px-0">
      <div className="hidden lg:block">
        <WelcomeUserWrapper />
      </div>
      {data?.data?.data?.showNotification &&
        rebateNotification &&
        !hideNotification && (
          <div className="mb-8">
            <RebatePopupWrapper
              fetchNotificationData={fetchNotificationData}
              updateNotificationStatus={setNotificationStatus}
            />
          </div>
        )}
      {hphPets.length > 0 &&
        petsToAddOrUpdateChanges.length > 0 &&
        !petsUpdatesNotificationDiscarded && (
          <div className="mb-6 sm:mt-2">
            <PetsUpdatesNotification
              onClose={() => setPetsUpdatesNotificationDiscarded(true)}
              onSeeChanges={() => onSeeChanges()}
            />
          </div>
        )}
      <PetsUpdatesNotificationModal
        petsChanges={petsToAddOrUpdateChanges}
        isOpen={isPetsUpdatesNotificationModalOpen}
        onClose={() => setIsPetsUpdatesNotificationModalOpen(false)}
        onConfirm={onPetsUpdatesNotificationConfirm}
        isLoading={isLoading}
      />
      <AddPetsFromSnowflakeModal
        petsChanges={petsToAddChanges}
        isOpen={isAddPetsFromSnowflakeModalOpen}
        onClose={() => setIsAddPetsFromSnowflakeModalOpen(false)}
        onConfirm={onAddPetsFromSnowflake}
        isLoading={isLoading}
      />
      <RemovePetsConfirmModal
        changesToRemove={petsToRemove}
        isOpen={isRemovePetsModalOpen}
        onClose={() => setIsRemovePetsModalOpen(false)}
        onGoBack={onGoBackToAddPetsFromSnowflake}
        onConfirm={onConfirmRemovePets}
        isLoading={isLoading}
      />
      <StartFromScratchModal
        isOpen={isStartFromScratchModalOpen}
        onClose={() => setIsStartFromScratchModalOpen(false)}
        onGoBack={onStartFromScratchGoBack}
        onConfirm={onStartFromScratchConfirm}
        isLoading={isLoading}
      />
      <PetsAddedModal
        isOpen={isPetsAddedModalOpen}
        onClose={() => setIsPetsAddedModalOpen(false)}
        onAddAnotherPet={onAddAnotherPet}
        onConfirm={onPetsAddedConfirm}
        isLoading={isLoading}
      />
      <div className="mb-6">
        <MyPetsWrapper />
      </div>
      <div className="mb-6">
        <AccountSettings />
      </div>
      {QotwEnabled && (
        <div className="mb-6">
          <QOTWWrapper />
        </div>
      )}
    </div>
  );
};

AccountHome.defaultProps = {};

export default AccountHome;
