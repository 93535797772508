import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Loader } from "ui-2";
import { DietInfoCardProps, PetType } from "../../../../../types";
import DietInfoCard from "../../../../components/DietInfoCard/DietInfoCard";
import { FeedingGuideAccordion } from "../../../../components/DietInfoCard/FeedingGuide/FeedingGuideAccordion";
import { FeedingGuideTitle } from "../../../../components/DietInfoCard/FeedingGuide/FeedingGuideTitle";
import GTM_ACTIONS from "../../../../config/gtmActions";
import { MY_PETS } from "../../../../constants/myPets";
import { datalayerPush } from "../../../../lib/gtm";
import { useProducts } from "@pages/MyPets/context/ProductsContext";

const DietInfoCardWrapper = ({
  petData,
  onFoodSelectionSaved,
  onClose,
}: {
  petData: PetType;
  onFoodSelectionSaved: () => void;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const products = useProducts({
    pet: Number(petData.petTypeId) === 1 ? "dog" : "cat",
  });
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const toggleAccordion = () => {
    if (!isAccordionOpen) {
      datalayerPush(GTM_ACTIONS.MY_PETS_DIET_CLICK_FEEDING_GUIDE);
    }
    setIsAccordionOpen(!isAccordionOpen);
  };

  const product = products.data?.find(
    (food) => food.id === petData.petFoodTypeInfo
  );

  const feedingGuideTitle = product ? t(MY_PETS.DIET.FEEDING_GUIDE) : null;

  const DietData: DietInfoCardProps = {
    petId: petData.petId,
    petFoodTypeId: petData.petFoodTypeId,
    petFoodTypeInfo: petData.petFoodTypeInfo,
    hillsProductImage: product?.primaryProductImageUrl,
    hillsProductDesc: product?.name,
    petName: petData.petName,
    petType: Number(petData.petTypeId) === 1 ? "dog" : "cat",
    petTypeId: petData.petTypeId,
  };

  if (products.isPending) return <Loader />;

  return (
    <DietInfoCard
      DietData={DietData}
      onFoodSelectionSaved={onFoodSelectionSaved}
      onClose={onClose}
    >
      {petData.petFoodTypeId === 1 && (
        <>
          {feedingGuideTitle && (
            <FeedingGuideTitle
              title={feedingGuideTitle}
              isOpen={isAccordionOpen}
              toggleAccordion={toggleAccordion}
            />
          )}
          {isAccordionOpen && product?.feedingGuide && (
            <FeedingGuideAccordion
              contentHtml={product.feedingGuide}
              isOpen={isAccordionOpen}
            />
          )}
        </>
      )}
    </DietInfoCard>
  );
};

export default DietInfoCardWrapper;
