import {
  fetchCatProducts,
  fetchDogProducts,
  Product,
} from "@api-calls/fetchProducts";
import React from "react";
import { StateWithShortcuts, usePromise } from "utils";

interface ProductsContextType {
  dogProductsState: StateWithShortcuts<Product[]>;
  catProductsState: StateWithShortcuts<Product[]>;
}

const ProductsContext = React.createContext<ProductsContextType | undefined>(
  undefined
);
ProductsContext.displayName = "ProductsContext";

export function ProductsProvider({
  children,
}: Readonly<{ children: React.ReactNode }>) {
  const [dogProductsState, { exec: execFetchDogProducts }] =
    usePromise(fetchDogProducts);
  const [catProductsState, { exec: execFetchCatProducts }] =
    usePromise(fetchCatProducts);

  React.useEffect(() => {
    execFetchDogProducts();
  }, [execFetchDogProducts]);

  React.useEffect(() => {
    execFetchCatProducts();
  }, [execFetchCatProducts]);

  return (
    <ProductsContext.Provider value={{ dogProductsState, catProductsState }}>
      {children}
    </ProductsContext.Provider>
  );
}

export function useProducts({ pet }: { pet: "dog" | "cat" }) {
  const context = React.useContext(ProductsContext);
  if (!context) {
    throw new Error("useProducts must be used within a ProductsProvider");
  }

  return pet === "dog" ? context.dogProductsState : context.catProductsState;
}
